import React, { useEffect } from "react";
import GingerBreadMan from "../assets/2024/fun/MUNECO-DULCE.gif";
import MunecoNieve from "../assets/2024/fun/PINGUINO.gif";
import nieve from "../assets/2024/recipes/NIEVE-FONDO.png";
import flavour from "../assets/2024/recipes/SABOR-QUE-TE-ACERCA-A-LA-NAVIDAD.png";
import flavourEng from "../assets/2024/recipes/SABOR-QUE-TE-ACERCA.INGLES.png";
import sorpresa from "../assets/2024/recipes/SORPRENDE-A-TUS-SERES-QUERIDOS.png";
import sorpresaEng from "../assets/2024/recipes/SOPRENDE-A-TUS-SERES-INGLES.png";

import sorpresaResponsive from "../assets/2024/recipes/SORPRENDE-RESPONSIVE.png";
import sorpresaResponsiveEng from "../assets/2024/recipes/SORPRENDE-RESPONSIVE-EN.png";

import { Col, Container, Row } from "react-bootstrap";
import { VideoView } from "../components/components/videoView";
import { useIntl } from "react-intl";
export const Recipes = () => {
  const intl = useIntl();

  const videoList = [
    {
      url: "sP6m38ha8Y8?si=Fv5R76KDJcT1IG9c",
    },
    {
      url: "px9iIj1FHGs?si=pU1luQL1RfB4YDREl",
    },
    {
      url: "Nlx5XpDxw_E?si=vNzadnClk7L14vbL",
    },
  ];

  useEffect(() => {
    if ("scrollRestoration" in window.history) {
      window.history.scrollRestoration = "manual";
    }

    window.scrollTo(0, 0); // Scroll to top

    return () => {
      if ("scrollRestoration" in window.history) {
        window.history.scrollRestoration = "auto";
      }
    };
  }, []);

  return (
    <div className="recipes__background">
      <img src={nieve} alr="Imagen fondo nieve" className="sectionBg__nieve" />
      <img
        src={MunecoNieve}
        className=" sectionBg__munecoNieve"
        alt="logo"
      ></img>
      <img
        src={GingerBreadMan}
        className="sectionBg__gingerBreadMan"
        alt="logo"
      ></img>
      <Container className="recipes__margin">
        <Row className="mt-5 ">
          <Col xs={12}>
            <img
              src={intl.locale.includes("es") ? flavour : flavourEng}
              className="recipes__welcome "
            ></img>
          </Col>
          <Col xs={12} className="mt-3 d-flex justify-content-center">
            <img
              src={intl.locale.includes("es") ? sorpresa : sorpresaEng}
              className="recipes__sorpresa hide__responsive"
            ></img>

            <img
              src={
                intl.locale.includes("es")
                  ? sorpresaResponsive
                  : sorpresaResponsiveEng
              }
              className="recipes__sorpresa show__responsive"
            ></img>
          </Col>
          <Col xs={12} className="mt-5">
            <VideoView videoList={videoList} />
          </Col>
        </Row>
      </Container>
    </div>
  );
};
