import React, { useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useIntl } from "react-intl";
import phone from "../assets/2024/fun/CELULAR.png";
import divierteteYJuegaEng from "../assets/2024/fun/DIVIERTETE-Y-JUEGA-eng.png";
import divierteteYJuega from "../assets/2024/fun/DIVIERTETE-Y-JUEGA.png";

import funEng from "../assets/2024/fun/FUN-RESPONSIVE-EN.png";
import funEs from "../assets/2024/fun/FUN-RESPONSIVE.png";

import igEng from "../assets/2024/fun/ABRIR-EN-IG-ENG.png";
import ig from "../assets/2024/fun/ABRIR-EN-IG.png";
import GingerBreadMan from "../assets/2024/fun/MUNECO-DULCE.gif";
import nieve from "../assets/2024/fun/NIEVE-FONDO.png";
import MunecoNieve from "../assets/2024/fun/PINGUINO.gif";
import flavorEng from "../assets/2024/fun/SABOR-QUE-TE-ACERCA-A-LA-ANVIDAD-eng.png";
import flavor from "../assets/2024/fun/SABOR-QUE-TE-ACERCA-A-LA-ANVIDAD.png";
import useAnalyticsEventTracker from "../services/useAnalyticsEventTracker";

export const FunSection = () => {
  const gaEvent = useAnalyticsEventTracker("Have Fun Section");
  const intl = useIntl();
  useEffect(() => {
    if ("scrollRestoration" in window.history) {
      window.history.scrollRestoration = "manual";
    }

    window.scrollTo(0, 0); // Scroll to top

    return () => {
      if ("scrollRestoration" in window.history) {
        window.history.scrollRestoration = "auto";
      }
    };
  }, []);
  return (
    <div className="fun__background">
      <img src={nieve} alr="Imagen fondo nieve" className="sectionBg__nieve" />
      <img
        src={MunecoNieve}
        className=" sectionBg__munecoNieve"
        alt="logo"
      ></img>
      <img
        src={GingerBreadMan}
        className="sectionBg__gingerBreadMan"
        alt="logo"
      ></img>
      <Container className="fun__margin">
        <Row className="mt-5 mb-1 mb-lg-5 justify-responsive">
          <Row className="show__responsive justify-content-center">
            <img
              className="fun__flavor"
              alt="El sabor que te acerca a la navidad"
              src={intl.locale.includes("es") ? flavor : flavorEng}
            />
          </Row>
          <Col xs={12} className="d-flex justify-content-center">
            <img
              src={
                intl.locale.includes("es")
                  ? divierteteYJuega
                  : divierteteYJuegaEng
              }
              className="fun__divierteteYJuega hide__responsive"
              alt="Divierte y juega"
            ></img>
            <img
              src={intl.locale.includes("es") ? funEs : funEng}
              className={
                intl.locale.includes("es")
                  ? "fun__divierteteYJuega show__responsive"
                  : "fun__divierteteYJuegaEn show__responsive"
              }
              alt="Divierte y juega"
            ></img>
          </Col>
          <Col
            xs={12}
            lg={6}
            className="mt-3 justify-responsive"
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
            }}
          >
            <img
              src={intl.locale.includes("es") ? phone : phone}
              className="fun__phone"
            ></img>
          </Col>
          <Col xs={6} className="mt-5 hide__responsive">
            <Row className="h-100 align-items-center">
              <Col xs={12}>
                <Row>
                  <img
                    className="fun__flavor"
                    alt="El sabor que te acerca a la navidad"
                    src={intl.locale.includes("es") ? flavor : flavorEng}
                  />
                </Row>
                <Row>
                  <a
                    style={{
                      display: "flex",
                      alignContent: "center",
                      justifyContent: "flex-start",
                    }}
                    href="https://www.instagram.com/ar/885728000042171/"
                    target="_blank"
                    onClick={() => {
                      gaEvent("Abrir filtro instagram");
                    }}
                  >
                    <img
                      className="fun__ig"
                      alt="Abrir en instagram"
                      src={intl.locale.includes("es") ? ig : igEng}
                    />
                  </a>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row className="show__responsive">
          <a
            style={{
              display: "flex",
              alignContent: "center",
              justifyContent: "center",
            }}
            href="https://www.instagram.com/ar/885728000042171/"
            target="_blank"
          >
            <img
              className="fun__ig"
              alt="Abrir en instagram"
              src={intl.locale.includes("es") ? ig : igEng}
            />
          </a>
        </Row>
      </Container>
    </div>
  );
};
