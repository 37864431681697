import React from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import bolasIcon from "../../../assets/2024/christmasCard/stickers/STICKER-1.png";
import gafasIcon from "../../../assets/2024/christmasCard/stickers/STICKER-2.png";
import gorroIcon from "../../../assets/2024/christmasCard/stickers/STICKER-3.png";
import regaloIcon from "../../../assets/2024/christmasCard/stickers/STICKER-4.png";

export const StickerManager = ({
  bolas,
  setBolas,
  gafas,
  setGafas,
  gorro,
  setGorro,
  regalo,
  setRegalo,
}) => {
  return (
    <Container className="christmasCard__addOnSelectorContainer">
      <Row className="christmasCard__addOnSelectorContainer__paddingRow">
        <Col>
          <Button
            id={bolas ? "selected" : ""}
            onClick={() => {
              setBolas(!bolas);
            }}
          >
            <img src={bolasIcon} alt="icono icon2" />
          </Button>
        </Col>
        <Col>
          <Button
            id={gafas ? "selected" : ""}
            onClick={() => {
              setGafas(!gafas);
            }}
          >
            <img src={gafasIcon} alt="icono icon2" />
          </Button>
        </Col>
        <Col>
          <Button
            id={gorro ? "selected" : ""}
            onClick={() => {
              setGorro(!gorro);
            }}
          >
            <img src={gorroIcon} alt="icono icon2" />
          </Button>
        </Col>
        <Col>
          <Button
            id={regalo ? "selected" : ""}
            onClick={() => {
              setRegalo(!regalo);
            }}
          >
            <img src={regaloIcon} alt="icono icon2" />
          </Button>
        </Col>
      </Row>
    </Container>
  );
};
