import React, { useState } from "react";
import { Button, Col, Overlay, Row, Tooltip } from "react-bootstrap";
import { RiFontSize } from "react-icons/ri";

export const FontSizeSelectorResponsive = ({
  fontSize,
  setFontSize,
  setShowSize,
  show,
  setShowFont,
  setShowColor,
}) => {
  const [target, setTarget] = useState(null);
  const ref = React.useRef(null);
  const handleClick = (event) => {
    setShowSize(!show);
    setTarget(event.target);
    setShowFont(false);
    setShowColor(false);
  };
  return (
    <div ref={ref}>
      <Button
        className="christmasCardResponsive__buttonSelector"
        id={show ? "selectedFont" : "fontMsg"}
        onClick={handleClick}
      >
        <RiFontSize size={15} />
      </Button>
      <Overlay target={target} show={show} placement="top">
        {(props) => (
          <Tooltip
            className="christmasCardResponsive__responsiveTooltip"
            id="overlay-tooltip"
            {...props}
          >
            <Row
              className="christmasCard__textManagerInnerButtonRow"
              style={{ height: "fit-content", padding: "0 5%" }}
            >
              <Col
                xs={4}
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Button
                  className="shadow-none christmasCard__fontButton"
                  onClick={() => {
                    setFontSize(fontSize === 0 ? 0 : fontSize - 1);
                  }}
                >
                  -
                </Button>
              </Col>
              <Col
                xs={4}
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <p style={{ fontWeight: "bold", fontSize: "15px" }}>
                  {fontSize}
                </p>
              </Col>
              <Col
                xs={4}
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Button
                  className="shadow-none christmasCard__fontButton"
                  onClick={() => {
                    setFontSize(fontSize + 1);
                  }}
                >
                  +
                </Button>
              </Col>
            </Row>
          </Tooltip>
        )}
      </Overlay>
    </div>
  );
};
