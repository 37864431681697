import React from "react";
import { Button, Container, Row } from "react-bootstrap";
import fondo1Card from "../../../assets/2024/christmasCard/cardBackgrounds/FONDO_CARD01.png";
import fondo2Card from "../../../assets/2024/christmasCard/cardBackgrounds/FONDO_CARD02.png";
import fondo3Card from "../../../assets/2024/christmasCard/cardBackgrounds/FONDO_CARD03.png";
import fondo4Card from "../../../assets/2024/christmasCard/cardBackgrounds/FONDO_CARD04.png";
import fondo1CardPre from "../../../assets/2024/christmasCard/cardBackgrounds/CARDLISTA01.png";
import fondo2CardPre from "../../../assets/2024/christmasCard/cardBackgrounds/CARDLISTA02.png";
import fondo3CardPre from "../../../assets/2024/christmasCard/cardBackgrounds/CARDLISTA03.png";
import fondo4CardPre from "../../../assets/2024/christmasCard/cardBackgrounds/CARDLISTA04.png";
import fondo1CardPreENG from "../../../assets/2024/christmasCard/cardBackgrounds/CARDLISTA01ENG.png";
import fondo2CardPreENG from "../../../assets/2024/christmasCard/cardBackgrounds/CARDLISTA02ENG.png";
import fondo3CardPreENG from "../../../assets/2024/christmasCard/cardBackgrounds/CARDLISTA03ENG.png";
import fondo4CardPreENG from "../../../assets/2024/christmasCard/cardBackgrounds/CARDLISTA04ENG.png";
import fondo2CardDesignENG from "../../../assets/2024/christmasCard/cardBackgrounds/CARDDESIGNENGLISH02.png";
import { FormattedMessage } from "react-intl";

export const BackGroundManager = ({
  setIndex,
  index,
  setShowCard,
  language,
}) => {
  return (
    <Container className="christmasCard__backgroundSelectorContainer">
      <Row className="christmasCard__backgroundSelectorRow">
        <p className="christmasCard__backgroundSelectorTitleOne">
          {" "}
          <FormattedMessage id="readyToSend" />{" "}
        </p>
        <Button
          onClick={() => {
            setIndex(1);
            setShowCard(true);
          }}
        >
          <img
            src={language.includes("es") ? fondo1CardPre : fondo1CardPreENG}
            alt="background 1"
            id={index === 1 ? "selected" : ""}
          ></img>
        </Button>
        <Button
          onClick={() => {
            setIndex(2);
            setShowCard(true);
          }}
        >
          <img
            src={language.includes("es") ? fondo2CardPre : fondo2CardPreENG}
            alt="background 2"
            id={index === 2 ? "selected" : ""}
          ></img>
        </Button>
        <Button
          onClick={() => {
            setIndex(3);
            setShowCard(true);
          }}
        >
          <img
            src={language.includes("es") ? fondo3CardPre : fondo3CardPreENG}
            alt="background 3"
            id={index === 3 ? "selected" : ""}
          ></img>
        </Button>
        <Button
          onClick={() => {
            setIndex(4);
            setShowCard(true);
          }}
        >
          <img
            src={language.includes("es") ? fondo4CardPre : fondo4CardPreENG}
            alt="background 4"
            id={index === 4 ? "selected" : ""}
          ></img>
        </Button>
      </Row>
      <Row className="christmasCard__backgroundSelectorRow">
        <p className="christmasCard__backgroundSelectorTitleTwo">
          {" "}
          <FormattedMessage id="personalizeIt" />{" "}
        </p>

        <Button
          onClick={() => {
            setIndex(5);
            setShowCard(true);
          }}
        >
          <img
            src={fondo1Card}
            alt="background 1"
            id={index === 5 ? "selected" : ""}
          ></img>
        </Button>
        <Button
          onClick={() => {
            setIndex(6);
            setShowCard(true);
          }}
        >
          <img
            src={language.includes("es") ? fondo2Card : fondo2CardDesignENG}
            alt="background 2"
            id={index === 6 ? "selected" : ""}
          ></img>
        </Button>
        <Button
          onClick={() => {
            setIndex(7);
            setShowCard(true);
          }}
        >
          <img
            src={fondo3Card}
            alt="background 3"
            id={index === 7 ? "selected" : ""}
          ></img>
        </Button>
        <Button
          onClick={() => {
            setIndex(8);
            setShowCard(true);
          }}
        >
          <img
            src={fondo4Card}
            alt="background 4"
            id={index === 8 ? "selected" : ""}
          ></img>
        </Button>
      </Row>
    </Container>
  );
};
