import React, { useState } from "react";
import { Button, Form, Overlay, Row, Tooltip } from "react-bootstrap";
import { RiFontSansSerif } from "react-icons/ri";
import { useIntl } from "react-intl";

export const FontTypeSelectorResponsive = ({
  fontFamily,
  setFontFamily,
  show,
  setShowFont,
  setShowSize,
  setShowColor,
}) => {
  const [target, setTarget] = useState(null);
  const ref = React.useRef(null);
  const intl = useIntl();
  const handleClick = (event) => {
    setShowFont(!show);
    setTarget(event.target);
    setShowSize(false);
    setShowColor(false);
  };
  function setFontFormat(font) {
    switch (font) {
      case "Fat Face":
        setFontFamily("fatFace");
        break;
      case "Courgette":
        setFontFamily("Courgette");
        break;
      case "Fredoka":
        setFontFamily("Fredoka");
        break;
      case "Lobster":
        setFontFamily("Lobster");
        break;
      case "Pacifico":
        setFontFamily("Pacifico");
        break;
      case "Satisfy":
        setFontFamily("Satisfy");
        break;
      case "Shadow Into Light":
        setFontFamily("ShadowIntoLight");
        break;
    }
  }
  return (
    <div ref={ref}>
      <Button
        className="christmasCardResponsive__buttonSelector"
        id={show ? "selectedFont" : "fontMsg"}
        onClick={handleClick}
      >
        <RiFontSansSerif size={15} />
      </Button>
      <Overlay target={target} show={show} placement="top">
        {(props) => (
          <Tooltip
            className="christmasCardResponsive__responsiveTooltip"
            id="overlay-tooltip"
            {...props}
          >
            <Row
              className="christmasCard__textManagerInnerButtonRow"
              style={{ height: "fit-content", padding: "0 5%" }}
            >
              <Form.Select
                size="lg"
                style={{ fontFamily: fontFamily }}
                onChange={(e) => {
                  setFontFormat(e.target.value);
                }}
              >
                <option>
                  {intl.locale.includes("es") ? "Fuente Letra" : "Letter Font"}
                </option>
                <option>Fat Face</option>
                <option>Courgette</option>
                <option>Fredoka</option>
                <option>Lobster</option>
                <option>Pacifico</option>
                <option>Satisfy</option>
                <option>Shadow Into Light</option>
              </Form.Select>
            </Row>
          </Tooltip>
        )}
      </Overlay>
    </div>
  );
};
