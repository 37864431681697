import Dropdown from "react-bootstrap/Dropdown";
import WPicon from "../../assets/modalCompartir/wpicon.png";
import Button from "react-bootstrap/Button";
import React, { useState, useRef } from "react";
import { WhatsappImage } from "../../services/imageServices";
import { Container, Row } from "react-bootstrap";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import Col from "react-bootstrap/Col";
import es from "react-phone-input-2/lang/es.json";
import { FormattedMessage } from "react-intl";
import { useIntl } from "react-intl";
import { Oval } from "react-loader-spinner";

function PhoneDropDown(props) {
  const intl = useIntl();
  function urlContentToDataUri(url) {
    return fetch(url)
      .then((response) => response.blob())
      .then(
        (blob) =>
          new Promise((callback) => {
            let reader = new FileReader();
            reader.onload = function () {
              callback(this.result);
            };
            reader.readAsDataURL(blob);
          })
      );
  }

  const [value, setValue] = useState(null);
  const [serviceMessage, setServiceMessage] = useState("");
  const [loading, setLoading] = useState(false);
  //Usage example using await:
  //console.log(props.language)
  const handleOnSubmit = async () => {
    setServiceMessage("");
    let dataUri = await urlContentToDataUri(props.url);

    if (value) {
      setLoading(true);
      const obj = {
        number: `+${value}`,
        image: dataUri,
        lang: props.language,
        caption: props.language.includes("es")
          ? "Hola " +
            (props.para === "Destinatario" || props.para === "To"
              ? ""
              : props.para) +
            ", te acaban de enviar un mensaje lleno de sabor que te acerca a la navidad" +
            (props.de === "Remitente" || props.de === "From"
              ? ""
              : " de parte de " + props.de) +
            ". Si quieres enviar una tarjeta a tus seres queridos, entra a este link https://www.navidadalacolombina.com"
          : "Hello " +
            (props.para === "Destinatario" || props.para === "To"
              ? ""
              : props.para) +
            ", you just recieved a message full of flavor that brings you closer to christmas," +
            (props.de === "Remitente" || props.de === "From"
              ? ""
              : " from " + props.de) +
            ". If you want to send your own card to your loved ones, enter this link https://www.navidadalacolombina.com",
      };

      const callApi = await WhatsappImage(obj);
      setLoading(false);
      if (callApi.status === 200) {
        if (callApi.data.error) {
          props.language.includes("es")
            ? setServiceMessage("No se pudo enviar la tarjeta")
            : setServiceMessage("The card couldn't be sent");
        } else {
          props.language.includes("es")
            ? setServiceMessage("Tarjeta Enviada Exitosamente")
            : setServiceMessage("Card sent succesfully");
        }
      } else {
        props.language.includes("es")
          ? setServiceMessage("No se pudo enviar la tarjeta")
          : setServiceMessage("The card couldn't be sent");
      }
    }
  };
  return (
    <Dropdown>
      <Dropdown.Toggle
        variant="success"
        id="dropdown-basic"
        className="dropdown-button"
      >
        <Button className="botonEnviara" onClick={handleOnSubmit}>
          {" "}
          <img src={WPicon} className="imagenBotonDentro" alt="logo"></img>
        </Button>
      </Dropdown.Toggle>

      <Dropdown.Menu>
        <Container>
          <Row>
            <Col xs={12} style={{ padding: "1rem" }}>
              <PhoneInput
                value={value}
                onChange={setValue}
                country={"co"}
                localization={es}
              />
            </Col>
            <Col
              xs={12}
              style={{
                textAlign: "center",
                padding: "1rem 1rem 0 1rem ",
                color: "white !important",
              }}
            >
              <p
                style={{ color: "white !important", fontFamily: "NunitoBold" }}
                className="modalLogin__title__sm__error"
              >
                {" "}
                <FormattedMessage
                  style={{ color: "red !important" }}
                  id="aditional"
                />{" "}
              </p>
            </Col>
            <Col xs={12} style={{ textAlign: "center", padding: "0.5rem" }}>
              <p
                style={{
                  color: "white",
                  textAlign: "justify",
                  padding: "0 10%",
                  fontFamily: "NunitoBold",
                }}
              >
                <FormattedMessage id="extraHello" />
                {props.para === "Destinatario" || props.para === "To"
                  ? ""
                  : " " + props.para}
                {","}
                <FormattedMessage id="extraMsj" />
                {props.de === "Remitente" || props.de === "From"
                  ? ""
                  : intl.formatMessage({ id: "extraDe" }) + props.de + "."}
                <FormattedMessage id="extraClose" />{" "}
                https://navidadalacolombina.com
              </p>
            </Col>
            <Col xs={12} style={{ textAlign: "center", padding: "1rem" }}>
              <Button
                className="botonMandar"
                onClick={handleOnSubmit}
                disabled={value === null}
              >
                <FormattedMessage id="sendW" />
              </Button>
            </Col>
            <Col
              xs={12}
              style={{
                textAlign: "center",
                padding: "1rem",
                color: "white",
                alignItems: "center",
                display: "flex",
                justifyContent: "center",
              }}
            >
              {loading === true ? (
                <Oval
                  height={20}
                  width={20}
                  color={"white"}
                  secondaryColor={"#114a87"}
                />
              ) : (
                <p
                  style={{ color: "white" }}
                  className="modalLogin__title__sm__error"
                >
                  {serviceMessage}
                </p>
              )}
            </Col>
          </Row>
        </Container>
      </Dropdown.Menu>
    </Dropdown>
  );
}

export default PhoneDropDown;
