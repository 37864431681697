import { useEffect, useState } from "react";
import { Route, Routes } from "react-router";
import { Layout } from "../components/layout/layout";
import { ChristmasCard } from "../pages/christmasCard";

import { Recipes } from "../pages/recipes";
import { Home } from "../pages/home2024";

import ReactGA from "react-ga4";
import { StartPage } from "../pages/startPage";
import { StartOptions } from "../pages/startOptions";
import { FunSection } from "../pages/fun";
import { StickersSection } from "../pages/stickers";
import backgroundMusic from "../assets/2024/music/jingle.mp3";
function AppRoutes({ setLanguage, lang, setPag, pag }) {
  const [play, setPlay] = useState(false);
  const [audio] = useState(new Audio(backgroundMusic));
  useEffect(() => {
    const location = window.location.pathname + window.location.search;
    ReactGA.send({
      hitType: "pageview",
      page: location,
    });
  });

  return (
    <Routes>
      <Route
        element={
          <Layout
            playing={play}
            setPlaying={setPlay}
            setPag={setPag}
            pag={pag}
            setLanguage={setLanguage}
            lang={lang}
            audio={audio}
          />
        }
      >
        <Route
          path="/"
          element={
            <Home
              playingPar={play}
              setPlayingPar={setPlay}
              setPag={setPag}
              setLanguage={setLanguage}
              lang={lang}
              audio={audio}
            />
          }
        />
        <Route path="/:pais" element={<StartPage />} />
        <Route path="/:pais/start" element={<StartOptions />} />
        <Route path="/:pais/stickers" element={<StickersSection />} />
        <Route
          path="/:pais/christmascard"
          element={<ChristmasCard language={lang} />}
        />
        <Route
          path="/:pais/recipes"
          element={
            <Recipes
              setPlay={setPlay}
              setPag={setPag}
              setLanguage={setLanguage}
            />
          }
        />
        <Route
          path="/:pais/fun"
          element={
            <FunSection
              setPlay={setPlay}
              setPag={setPag}
              setLanguage={setLanguage}
            />
          }
        />
      </Route>
    </Routes>
  );
}
export default AppRoutes;
