import React from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import cascaNuecesIcon from "../../../assets/2024/christmasCard/avatares/BOTON-CASCANUECES.png";
import cascaNuecesAzulIcon from "../../../assets/2024/christmasCard/avatares/BOTON-CASCANUECES-AZUL.png";
import gnomoIcon from "../../../assets/2024/christmasCard/avatares/BOTON-NOMO.png";
import renoRojoIcon from "../../../assets/2024/christmasCard/avatares/BOTON-RNO-ROJO.png";
import renoVerdeIcon from "../../../assets/2024/christmasCard/avatares/BOTON-RNO-VERDE.png";
import gingerBreadIcon from "../../../assets/2024/christmasCard/avatares/GINGER_BREAD_ICON.png";
import munecoNieveVerdeIcon from "../../../assets/2024/christmasCard/avatares/MUNECO_NIEVE_VERDE.png";
import munecoNieveRojoIcon from "../../../assets/2024/christmasCard/avatares/MUNECO_NIEVE_ROJO.png";

export const IconManager = ({
  icon1,
  setIcon1,
  icon3,
  setIcon3,
  icon4,
  setIcon4,
  icon2,
  setIcon2,
  icon5,
  setIcon5,
  icon6,
  setIcon6,
  icon7,
  setIcon7,
  icon8,
  setIcon8,
}) => {
  return (
    <Container className="christmasCard__addOnSelectorContainer">
      <Row style={{ marginBottom: "1vh", width: "100%" }}>
        <Col xs={3}>
          <Button
            id={icon1 ? "selected" : ""}
            onClick={() => {
              setIcon1(!icon1);
            }}
          >
            <img src={munecoNieveVerdeIcon} alt="icono icon1" />
          </Button>
        </Col>
        <Col xs={3}>
          <Button
            id={icon2 ? "selected" : ""}
            onClick={() => {
              setIcon2(!icon2);
            }}
          >
            <img src={gnomoIcon} alt="icono icon2" />
          </Button>
        </Col>
        <Col xs={3}>
          <Button
            id={icon3 ? "selected" : ""}
            onClick={() => {
              setIcon3(!icon3);
            }}
          >
            <img src={gingerBreadIcon} alt="icono icon3" />
          </Button>
        </Col>

        <Col xs={3}>
          <Button
            id={icon4 ? "selected" : ""}
            onClick={() => {
              setIcon4(!icon4);
            }}
          >
            <img src={cascaNuecesIcon} alt="icono Reno" />
          </Button>
        </Col>
      </Row>
      <Row style={{ marginBottom: "3vh", width: "100%" }}>
        <Col xs={3}>
          <Button
            id={icon5 ? "selected" : ""}
            onClick={() => {
              setIcon5(!icon5);
            }}
          >
            <img src={renoRojoIcon} alt="icono icon2" />
          </Button>
        </Col>
        <Col xs={3}>
          <Button
            id={icon6 ? "selected" : ""}
            onClick={() => {
              setIcon6(!icon6);
            }}
          >
            <img src={renoVerdeIcon} alt="icono icon5" />
          </Button>
        </Col>
        <Col xs={3}>
          <Button
            id={icon7 ? "selected" : ""}
            onClick={() => {
              setIcon7(!icon7);
            }}
          >
            <img src={cascaNuecesAzulIcon} alt="icono icon7" />
          </Button>
        </Col>
        <Col xs={3}>
          <Button
            id={icon8 ? "selected" : ""}
            onClick={() => {
              setIcon8(!icon8);
            }}
          >
            <img src={munecoNieveRojoIcon} alt="icono Reno" />
          </Button>
        </Col>
      </Row>
    </Container>
  );
};
