import React, { useEffect, useState } from "react";
import { Col, Container, Row, Spinner } from "react-bootstrap";
import { FaCheckCircle } from "react-icons/fa";
import { VscError } from "react-icons/vsc";
import { useIntl } from "react-intl";
import GingerBreadMan from "../assets/2024/fun/MUNECO-DULCE.gif";
import MunecoNieve from "../assets/2024/fun/PINGUINO.gif";
import botonEnviarEn from "../assets/2024/stickers/BOTON-ENVIAR-EN.png";
import botonEnviar from "../assets/2024/stickers/BOTON-ENVIAR.png";
import botonLlenarCelualr from "../assets/2024/stickers/BOTON-ESCRIBE-WAPP.png";
import phone from "../assets/2024/stickers/CELULAR.png";
import escribeTelEN from "../assets/2024/stickers/ESCRIBE-TU-NUMERO-DE-CELULAR-EN.png";
import escribeTel from "../assets/2024/stickers/ESCRIBE-TU-NUMERO-DE-CELULAR.png";
import nieve from "../assets/2024/stickers/NIEVE-FONDO.png";
import flavorEng from "../assets/2024/stickers/SABOR-QUE-TE-ACERCA-A-LA-ANVIDAD-eng.png";
import flavor from "../assets/2024/stickers/SABOR-QUE-TE-ACERCA-A-LA-ANVIDAD.png";

import s1 from "../assets/2024/stickers/S1.gif";
import s2 from "../assets/2024/stickers/S2.gif";
import s3 from "../assets/2024/stickers/S3.gif";
import s4 from "../assets/2024/stickers/S4.gif";
import s5 from "../assets/2024/stickers/S5.gif";
import s6 from "../assets/2024/stickers/S6.gif";
import s7 from "../assets/2024/stickers/S7.gif";
import s8 from "../assets/2024/stickers/S8.gif";

import s1Eng from "../assets/2024/stickers/s1Eng.gif";
import s2Eng from "../assets/2024/stickers/s2Eng.gif";
import s3Eng from "../assets/2024/stickers/s3Eng.gif";
import s4Eng from "../assets/2024/stickers/s4Eng.gif";
import s5Eng from "../assets/2024/stickers/s5Eng.gif";
import s6Eng from "../assets/2024/stickers/s6Eng.gif";

import PhoneInput from "react-phone-input-2";
import es from "react-phone-input-2/lang/es.json";
import { WhatsappSticker } from "../services/imageServices";
import useAnalyticsEventTracker from "../services/useAnalyticsEventTracker";

export const StickersSection = () => {
  const gaEventTracker = useAnalyticsEventTracker("Stickers");

  const intl = useIntl();
  const [selectedImage, setSelectedImage] = useState(s1);
  const [selectedImageName, setSelectedImageName] = useState("MOOD_NOVENA");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [loading, setLoading] = useState(false);
  const [sendStatus, setSendStatus] = useState(null);
  const sticker_list = [
    { image: s1, name: "MOOD_NOVENA" },
    { image: s2, name: "TUTAINA" },
    { image: s3, name: "QUE_HAY_PA_HOY" },
    { image: s4, name: "MERRY_XMAS" },
    { image: s5, name: "LLEGO_DICIEMBRE" },
    { image: s6, name: "FELIZ_NEW_YEAR" },
    { image: s7, name: "HOY_ES_NAVIDAD" },
    { image: s8, name: "FELIZ_NAVIDAD_GINGERBREAD" },
  ];
  const sticker_list_eng = [
    { image: s1Eng, name: "WHATS_UP_TODAY" },
    { image: s2Eng, name: "TODAY_IS_CHRISTMAS" },
    { image: s3Eng, name: "MERRY_XMAS" },
    { image: s4Eng, name: "MERRY_CHRISTMAS_GINGERBREAD" },
    { image: s5Eng, name: "HAPPY_NEW_YEAR" },
    { image: s6Eng, name: "DECEMBER_IS_HERE" },
  ];
  useEffect(() => {
    if ("scrollRestoration" in window.history) {
      window.history.scrollRestoration = "manual";
    }

    window.scrollTo(0, 0); // Scroll to top

    return () => {
      if ("scrollRestoration" in window.history) {
        window.history.scrollRestoration = "auto";
      }
    };
  }, []);

  const handleSendSticker = async () => {
    try {
      setLoading(true);
      setSendStatus(null);
      gaEventTracker("Sticker: " + selectedImageName);
      const resultado = await WhatsappSticker({
        img: selectedImageName,
        to: phoneNumber,
      });
      if (resultado.data.error) {
        setSendStatus(false);
      } else {
        setSendStatus(true);
      }
    } catch (e) {
    } finally {
      setLoading(false);
    }
  };
  return (
    <div className="stickers__background">
      <img src={nieve} alt="Imagen fondo nieve" className="sectionBg__nieve" />
      <img
        src={MunecoNieve}
        className=" sectionBg__munecoNieve"
        alt="logo"
      ></img>
      <img
        src={GingerBreadMan}
        className="sectionBg__gingerBreadMan"
        alt="logo"
      ></img>
      <Container className="stickers__margin">
        <Row
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
          className="show__responsive"
        >
          <img
            className="stickers__flavor"
            alt="El sabor que te acerca a la navidad"
            src={intl.locale.includes("es") ? flavor : flavorEng}
          />
        </Row>
        <Row className="mt-1 mb-5 justify-content-center">
          <Col
            xs={6}
            lg={4}
            className="mt-3"
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
            }}
          >
            <img
              alt="Sticker Phone"
              src={intl.locale.includes("es") ? phone : phone}
              className="stickers__phone"
            ></img>
          </Col>
          <Col xs={6} className="mt-5">
            <Row
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <img
                className="stickers__flavor hide__responsive"
                alt="El sabor que te acerca a la navidad"
                src={intl.locale.includes("es") ? flavor : flavorEng}
              />
            </Row>
            <Row
              style={{
                position: "relative",
                zIndex: "5",
                paddingLeft: "10%",
                paddingRight: "10%",
              }}
              className="justify-content-center"
            >
              {intl.locale.includes("es") &&
                sticker_list.map((item, index) => {
                  return (
                    <Col xs={6} lg={3} key={index}>
                      <div
                        className="stickers__stickerHolder"
                        id={selectedImage === item.image ? "selected" : ""}
                        onClick={() => {
                          setSelectedImage(item.image);
                          setSelectedImageName(item.name);
                        }}
                      >
                        <img
                          alt={item.name}
                          src={item.image}
                          className="stickers__sticker"
                        />
                      </div>
                    </Col>
                  );
                })}
              {intl.locale.includes("en") &&
                sticker_list_eng.map((item, index) => {
                  return (
                    <Col xs={6} lg={3} key={index}>
                      <div
                        className="stickers__stickerHolder"
                        id={selectedImage === item.image ? "selected" : ""}
                        onClick={() => {
                          setSelectedImage(item.image);
                          setSelectedImageName(item.name);
                        }}
                      >
                        <img
                          alt={item.name}
                          src={item.image}
                          className="stickers__sticker"
                        />
                      </div>
                    </Col>
                  );
                })}
            </Row>
            <Row
              style={{
                position: "relative",
                zIndex: "10",
                marginTop: "3%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
              className="hide__responsive"
            >
              <img
                className="stickers__description"
                alt={"Escribe tu telefono"}
                src={intl.locale.includes("es") ? escribeTel : escribeTelEN}
              ></img>
            </Row>
            <Row
              style={{
                position: "relative",
                zIndex: "5",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
              className="hide__responsive"
            >
              <div className="stickers__whatsappNumber">
                <PhoneInput
                  className="inputWp"
                  country={"co"}
                  localization={es}
                  value={phoneNumber}
                  onChange={setPhoneNumber}
                />
                {/* <input
                  type="number"
                  className="inputWp"
                  placeholder="Escribe tu celular"
                  value={phoneNumber}
                  onChange={(event) => {
                    setPhoneNumber(event.target.value);
                  }}
                ></input> */}
                <img
                  className="stickers__botonWhatsapp"
                  src={botonLlenarCelualr}
                  alt="botonLlenarWhatsapp "
                />
              </div>
            </Row>
            <Row
              style={{
                position: "relative",
                zIndex: "4",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "row",
              }}
              className="hide__responsive"
            >
              <img
                className="stickers__botonEnviar"
                alt="Boton Enviar"
                src={intl.locale.includes("es") ? botonEnviar : botonEnviarEn}
                onClick={() => {
                  handleSendSticker();
                }}
              />
              {loading && (
                <Spinner animation="border" role="status" color="white" />
              )}
              {sendStatus === true && (
                <FaCheckCircle
                  size={36}
                  color="white"
                  style={{ width: "fit-content" }}
                />
              )}
              {sendStatus === false && (
                <VscError
                  size={36}
                  color="white"
                  style={{ width: "fit-content" }}
                />
              )}
            </Row>
          </Col>
        </Row>
        <Row
          style={{
            position: "relative",
            zIndex: "5",
            marginTop: "3%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
          className="show__responsive"
        >
          <img
            className="stickers__description"
            alt={"Escribe tu telefono"}
            src={intl.locale.includes("es") ? escribeTel : escribeTelEN}
          ></img>
        </Row>
        <Row
          style={{
            position: "relative",
            zIndex: "5",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
          className="show__responsive"
        >
          <div className="stickers__whatsappNumber">
            <PhoneInput
              className="inputWp"
              country={"co"}
              localization={es}
              value={phoneNumber}
              onChange={setPhoneNumber}
            />
            <img
              className="stickers__botonWhatsapp"
              src={botonLlenarCelualr}
              alt="botonLlenarWhatsapp "
            />
          </div>
        </Row>
        <Row
          style={{
            position: "relative",
            zIndex: "10",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
          className="show__responsive"
        >
          <img
            className="stickers__botonEnviar"
            alt="Boton Enviar"
            src={intl.locale.includes("es") ? botonEnviar : botonEnviarEn}
            onClick={() => {
              handleSendSticker();
            }}
          />
          {loading && (
            <Spinner animation="border" role="status" color="white" />
          )}
          {sendStatus === true && (
            <FaCheckCircle
              size={36}
              color="white"
              style={{ width: "fit-content" }}
            />
          )}
          {sendStatus === false && (
            <VscError
              size={36}
              color="white"
              style={{ width: "fit-content" }}
            />
          )}
        </Row>
      </Container>
    </div>
  );
};
