import { LOCALES } from "../locales";

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  [LOCALES.SPANISH]: {
    navbarHome: "Inicio",
    navbarAbout: "Sobre Mí",
    lan: "Español",
    registerButton: "Crear Tarjeta",
    registerTitle: "INGRESA A TU CUENTA",
    email: "CORREO ELECTRONICO",
    password: "CONTRASEÑA",
    documentNumber: "Cédula",
    confirmPass: "CONFIRMAR CONTRASEÑA",
    names: "Nombre",
    country: "País",
    lastNames: "APELLIDOS",
    createAccount: "CREAR CUENTA",
    login: "Ingresar",
    dont: "¿No tienes cuenta?",
    create: " Crear cuenta",
    send: "Enviar",
    email: "Correo electrónico",
    birthday: "CUMPLEAÑOS",
    gender: "Genero",
    age: "Edad",
    male: "HOMBRE",
    female: "MUJER",
    other: "OTRO",
    catalog: "Catálogo de productos",
    shareFamily: "Compartir en familia momentos llenos de alegría",
    experience: " Descubrir nuestros productos de temporada y vivir la navidad",
    colombianTerms:
      "¿Estás en Cali, Bogotá, Medellín o Barranquilla? Compra tus galletas en nuestra tienda virtual",

    terms: "Declaro que conozco y acepto la ",
    privacy: "Politica de Privacidad",
    termsConditions: " Términos y Condiciones  de Uso ",
    termsData: "y La Política de  Manejo de Datos Personales",
    endTerms: " de COLOMBINA S.A contenidas al pie de esta página web.",
    wrongPass: "Usuario o documento incorrecto",
    notRegister: "El correo no se encuentra registrado",
    alreadyRegister:
      "El correo o documento asociado ya se encuentra registrado",

    personalizeCard: "Personaliza tu tarjeta",
    loadPicture: "CARGAR FOTO",
    replacePicture: "REEMPLAZAR FOTO",
    letsStart: "¡Vamos a empezar!",
    background: "Fondos",
    text: "Textos",
    characters: "Personajes",
    stickers: "Stickers",
    xmasMessage: "Mensaje de Navidad",
    remitent: "De",
    destinatary: "Para",
    saveShare: " Guardar / Compartir ",
    wellDone: "¡Muy bien!",
    download: "Guardar en tu carrete",
    share: "Enviar por:",
    browsing: "Continuar en la web",

    back: "Volver al login",
    sendW: "Enviar",
    aditional: "Mensaje en la tarjeta",
    createFirst: "Oops... Por favor crea una carta primero",

    extraHello: "Hola",
    extraMsj:
      " te acaban de enviar un mensaje lleno de sabor que te acerca a la navidad,",
    extraDe: " de parte de ",
    extraClose:
      " Si quieres enviar una tarjeta a tus seres queridos, entra a este link ",

    createCard: "Cargar foto",
    startOver: "Crear nueva tarjeta",
    readyToSend: "¡Listas para enviar!",
    personalizeIt: "¡Personaliza con tu foto favorita!",
    predisenadas: "Prediseñadas",
    textura: "Personalizadas",
    xmasMessageResp: "Mensaje",
  },
};
