import React from "react";
import { Row } from "react-bootstrap";
import Tren from "../../../assets/2024/startPage/TREN.gif";

export const BlueSection = () => {
  return (
    <Row className="startPage__blue">
      <img src={Tren} className="startPage__blue__train" alt="Tren" />
    </Row>
  );
};
