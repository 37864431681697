import React from "react";
import { Col, Row } from "react-bootstrap";
import BastonIzquierda from "../../../assets/2024/startPage/BARRA-BASTON-DULCE-01.png";
import BastonDerecha from "../../../assets/2024/startPage/BARRA-BASTON-DULCE-02.png";

import Encuentra from "../../../assets/2024/startPage/ENCUENTRA-AQUI-TUS-PRODUCTOS.png";
import EncuentraEn from "../../../assets/2024/startPage/ENCUENTRA-AQUI-TUS-PRODUCTOS-EN.png";

import BuyRappi from "../../../assets/2024/startPage/BOTON-COMPRA-RAPPI.png";
import BuyRappiEn from "../../../assets/2024/startPage//BOTON-COMPRA-RAPPI-EN.png";

import BARA_VERTICAL from "../../../assets/BARA_VERTICAL.png";

import Galletas from "../../../assets/2024/startPage/GALLETAS-INFERIOR.png";
import Cascanueces from "../../../assets/2024/startPage/CASCANUECES.png";
import CascanuecesResponsive from "../../../assets/2024/startPage/CASCANUECES_RESPONSIVE.png";
import EstasEn from "../../../assets/2024/startPage/ESTAS-EN-CALI-O-BOGOTA.png";
import marketLogo from "../../../assets/LOGO_MARKET_COLOMBINA.png";
import { useIntl } from "react-intl";
import { useLocation } from "react-router";

import TextoNoRappiEn from "../../../assets/2024/startPage/TITULO-INGLES.png";
import TextoNoRappiEs from "../../../assets/2024/startPage/TITULO-ESPANOL.png";
import Bodegon from "../../../assets/2024/startPage/BODEGON.png";

import flavor from "../../../assets/2024/fun/SABOR-QUE-TE-ACERCA-A-LA-ANVIDAD.png";
import flavorEng from "../../../assets/2024/fun/SABOR-QUE-TE-ACERCA-A-LA-ANVIDAD-eng.png";
import copo from "../../../assets/2024/startPage/COPO-DE-NIEVE-01.png";
import useAnalyticsEventTracker from "../../../services/useAnalyticsEventTracker";
export const RedSection = () => {
  const path = useLocation().pathname;
  const intl = useIntl();
  const donde = path.split("/")[1];
  const gaEventTracker = useAnalyticsEventTracker("Christmas Card");

  return (
    <Row
      className={`startPage__red ${
        donde !== "COLOMBIA"
          ? "alignCenter__Responsive paddingBottom__Responsive"
          : ""
      }`}
    >
      <Row
        className={` min-content-height hide__responsive ${
          donde === "COLOMBIA"
            ? "justify-content-center"
            : "justify-content-center"
        }`}
        style={{ paddingRight: "15%", paddingLeft: "15%" }}
      >
        {donde === "COLOMBIA" && (
          <>
            <Col xs={5} className="text-right">
              {" "}
              <img
                src={intl.locale.includes("es") ? Encuentra : EncuentraEn}
                className="w-100"
                alt="Boton de comienzo"
              />
            </Col>
            <Col xs={1}>
              {" "}
              <img
                src={BARA_VERTICAL}
                alt="market"
                width="2rem"
                height="100%"
              ></img>{" "}
            </Col>
            <Col xs={4} className="text-start">
              {" "}
              <a
                onClick={() => {
                  gaEventTracker("Abrir menu de Rappi");
                }}
                href={
                  "https://www.rappi.com.co/tiendas/900266484-colombinamarket-brand-nc"
                }
                target="__blank"
                on
              >
                <img
                  src={intl.locale.includes("es") ? BuyRappi : BuyRappiEn}
                  className="w-100"
                  alt="Boton de comienzo"
                />
              </a>
            </Col>
          </>
        )}
        {donde !== "COLOMBIA" && (
          <>
            <Col xs={5} className="text-center hide__responsive">
              {" "}
              <img
                src={
                  intl.locale.includes("es") ? TextoNoRappiEs : TextoNoRappiEn
                }
                className="w-100"
                alt="Boton de comienzo"
              />
            </Col>
          </>
        )}
      </Row>

      <Row
        className="justify-content-center min-content-height show__responsive"
        style={{ paddingRight: "15%", paddingLeft: "15%" }}
      >
        <Col
          xs={12}
          className={`text-center ${
            donde !== "COLOMBIA" ? "d-none" : "d-block"
          }`}
        >
          <img
            src={intl.locale.includes("es") ? Encuentra : EncuentraEn}
            alt="Boton de comienzo"
            style={{ width: "30rem", paddingTop: "2rem" }}
          />
        </Col>

        <Col
          xs={12}
          className={`  ${
            donde !== "COLOMBIA"
              ? "d-block show__responsive"
              : "d-none hide__responsive"
          }`}
        >
          <img
            alt="El sabor que te acerca a la navidad"
            src={intl.locale.includes("es") ? flavor : flavorEng}
            className="startPage__red__flavor"
          />{" "}
        </Col>
        <Col
          xs={12}
          className={`text-center ${
            donde !== "COLOMBIA" ? "d-none" : "d-block"
          }`}
        >
          {" "}
          <a
            href={
              "https://www.rappi.com.co/tiendas/900266484-colombinamarket-brand-nc"
            }
            target="__blank"
          >
            <img
              src={intl.locale.includes("es") ? BuyRappi : BuyRappiEn}
              style={{ width: "16rem" }}
              alt="Boton de comienzo"
            />
          </a>
        </Col>
      </Row>

      <img
        src={Bodegon}
        className={` startPage__red__renoNoRappi  ${
          donde === "COLOMBIA" ? "d-none" : "d-block"
        }`}
        alt="Bodegon"
      />
      <img
        src={BastonIzquierda}
        className="startPage__red__bastonIzquierda"
        alt="Baston dulce"
      />
      <img
        src={BastonDerecha}
        className="startPage__red__bastonDerecha"
        alt="Baston dulce"
      />
      <img
        src={Galletas}
        className={`startPage__red__cookies ${
          donde !== "COLOMBIA" ? "d-none" : ""
        }`}
        alt="Baston dulce"
        style={{}}
      />

      <img
        src={Cascanueces}
        className={`startPage__red__nut hide__responsive ${
          donde !== "COLOMBIA" ? "d-none" : ""
        }`}
        alt="Cascanueces"
      />

      <img
        src={CascanuecesResponsive}
        className={`startPage__red__nut__responsive  ${
          donde !== "COLOMBIA" ? "d-none " : "show__responsive"
        }`}
        alt="Cascanueces"
      />
      <img
        src={copo}
        className={`startPage__red__rightSnow ${
          donde === "COLOMBIA" ? "d-none " : ""
        }`}
        alt="Copo"
      />
      <img
        src={copo}
        className={`startPage__red__leftSnow  ${
          donde === "COLOMBIA" ? "d-none " : ""
        }`}
        alt="Copo"
      />
    </Row>
  );
};
