import React, { useState } from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import fondo1CardPre from "../../../assets/2024/christmasCard/cardBackgrounds/CARDLISTA01.png";
import fondo1CardPreENG from "../../../assets/2024/christmasCard/cardBackgrounds/CARDLISTA01ENG.png";
import fondo2CardPre from "../../../assets/2024/christmasCard/cardBackgrounds/CARDLISTA02.png";
import fondo2CardPreENG from "../../../assets/2024/christmasCard/cardBackgrounds/CARDLISTA02ENG.png";
import fondo3CardPre from "../../../assets/2024/christmasCard/cardBackgrounds/CARDLISTA03.png";
import fondo3CardPreENG from "../../../assets/2024/christmasCard/cardBackgrounds/CARDLISTA03ENG.png";
import fondo4CardPre from "../../../assets/2024/christmasCard/cardBackgrounds/CARDLISTA04.png";
import fondo4CardPreENG from "../../../assets/2024/christmasCard/cardBackgrounds/CARDLISTA04ENG.png";
import fondo1Card from "../../../assets/2024/christmasCard/cardBackgrounds/FONDO_CARD01.png";
import fondo2Card from "../../../assets/2024/christmasCard/cardBackgrounds/FONDO_CARD02.png";
import fondo3Card from "../../../assets/2024/christmasCard/cardBackgrounds/FONDO_CARD03.png";
import fondo4Card from "../../../assets/2024/christmasCard/cardBackgrounds/FONDO_CARD04.png";
import fondo2CardDesignENG from "../../../assets/2024/christmasCard/cardBackgrounds/CARDDESIGNENGLISH02.png";

import { useIntl } from "react-intl";

export const BackGroundManagerResponsive = ({
  setIndex,
  index,
  setShowCard,
  language,
}) => {
  const intl = useIntl();
  const [selectedBgs, setSelectedBgs] = useState(1);
  return (
    <Container className="christmasCardResponsive__backgroundSelectorContainer">
      <Row className="christmasCardResponsive__backgroundTypeRow">
        <Col>
          <Button
            id={selectedBgs == 1 ? "selected" : ""}
            className="christmasCardResponsive__buttonSelector"
            onClick={() => {
              setSelectedBgs(1);
            }}
          >
            {intl.formatMessage({ id: "predisenadas" })}
          </Button>
        </Col>
        <Col>
          <Button
            id={selectedBgs == 2 ? "selected" : ""}
            className="christmasCardResponsive__buttonSelector"
            onClick={() => {
              setSelectedBgs(2);
            }}
          >
            {intl.formatMessage({ id: "textura" })}
          </Button>
        </Col>
      </Row>
      {selectedBgs == 1 && (
        <Row className="christmasCardResponsive__backgroundRow">
          <Col xs={3} className="christmasCardResponsive__bgCol">
            <Button
              onClick={() => {
                setIndex(1);
                setShowCard(true);
              }}
            >
              <img
                src={language.includes("es") ? fondo1CardPre : fondo1CardPreENG}
                alt="background 1"
                id={index === 1 ? "selected" : ""}
              ></img>
            </Button>
          </Col>
          <Col xs={3} className="christmasCardResponsive__bgCol">
            <Button
              onClick={() => {
                setIndex(2);
                setShowCard(true);
              }}
            >
              <img
                src={language.includes("es") ? fondo2CardPre : fondo2CardPreENG}
                alt="background 2"
                id={index === 2 ? "selected" : ""}
              ></img>
            </Button>
          </Col>
          <Col xs={3} className="christmasCardResponsive__bgCol">
            <Button
              onClick={() => {
                setIndex(3);
                setShowCard(true);
              }}
            >
              <img
                src={language.includes("es") ? fondo3CardPre : fondo3CardPreENG}
                alt="background 3"
                id={index === 3 ? "selected" : ""}
              ></img>
            </Button>
          </Col>
          <Col xs={3} className="christmasCardResponsive__bgCol">
            <Button
              onClick={() => {
                setIndex(4);
                setShowCard(true);
              }}
            >
              <img
                src={language.includes("es") ? fondo4CardPre : fondo4CardPreENG}
                alt="background 4"
                id={index === 4 ? "selected" : ""}
              ></img>
            </Button>
          </Col>
        </Row>
      )}
      {selectedBgs == 2 && (
        <Row className="christmasCardResponsive__backgroundRow">
          <Col xs={3} className="christmasCardResponsive__bgCol">
            <Button
              onClick={() => {
                setIndex(5);
                setShowCard(true);
              }}
            >
              <img
                src={fondo1Card}
                alt="background 5"
                id={index === 5 ? "selected" : ""}
              ></img>
            </Button>
          </Col>
          <Col xs={3} className="christmasCardResponsive__bgCol">
            <Button
              onClick={() => {
                setIndex(6);
                setShowCard(true);
              }}
            >
              <img
                src={language.includes("es") ? fondo2Card : fondo2CardDesignENG}
                alt="background 6"
                id={index === 6 ? "selected" : ""}
              ></img>
            </Button>
          </Col>
          <Col xs={3} className="christmasCardResponsive__bgCol">
            <Button
              onClick={() => {
                setIndex(7);
                setShowCard(true);
              }}
            >
              <img
                src={fondo3Card}
                alt="background 3"
                id={index === 7 ? "selected" : ""}
              ></img>
            </Button>
          </Col>
          <Col xs={3} className="christmasCardResponsive__bgCol">
            <Button
              onClick={() => {
                setIndex(8);
                setShowCard(true);
              }}
            >
              <img
                src={fondo4Card}
                alt="background 8"
                id={index === 8 ? "selected" : ""}
              ></img>
            </Button>
          </Col>
        </Row>
      )}
    </Container>
  );
};
