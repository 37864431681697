import React from "react";
import { useIntl } from "react-intl";
import { useLocation } from "react-router";

export const Footer = () => {
  const intl = useIntl();
  const path = useLocation().pathname;

  return (
    <div>
      <div
        style={{}}
        className="footer"
        id={
          path.toUpperCase().includes("CHRISTMASCARD") ? "tarjeta" : "noTarjeta"
        }
      >
        {intl.locale.includes("es") ? (
          <p>
            2024 © Colombina S.A -
            <a
              style={{ color: "white" }}
              target="_blank"
              href={
                "https://colombina.com//uploads/admin/media/upload/tratamiendo-de-datos/politica_proteccion_datos_personales_colombina_2020.pdf"
              }
              rel="noreferrer"
            >
              Políticas de protección de datos personales
            </a>
            {" | "}
            <a
              style={{ color: "white" }}
              target="_blank"
              href={
                "https://fundacioncolombina.com/wp-content/uploads/2017/10/Terminos-y-condiciones.pdf"
              }
              rel="noreferrer"
            >
              Términos y condiciones
            </a>
            {" | "}
            <a
              style={{ color: "white" }}
              target="_blank"
              href={
                "https://colombina.com/uploads/strategic-framework/5f3474da1b51fColombina_aviso-de-privacidad.pdf"
              }
              rel="noreferrer"
            >
              Aviso de privacidad
            </a>
          </p>
        ) : (
          <p>
            2024 © Colombina S.A -
            <a
              style={{ color: "white" }}
              target="_blank"
              href={
                "https://colombina.com//uploads/admin/media/upload/tratamiendo-de-datos/politica_proteccion_datos_personales_colombina_2020.pdf"
              }
              rel="noreferrer"
            >
              Personal data protection policies
            </a>
            {" | "}
            <a
              style={{ color: "white" }}
              target="_blank"
              href={
                "https://fundacioncolombina.com/wp-content/uploads/2017/10/Terminos-y-condiciones.pdf"
              }
              rel="noreferrer"
            >
              Terms and Conditions
            </a>
            {" | "}
            <a
              style={{ color: "white" }}
              target="_blank"
              href={
                "https://colombina.com/uploads/strategic-framework/5f3474da1b51fColombina_aviso-de-privacidad.pdf"
              }
              rel="noreferrer"
            >
              Privacy Notice
            </a>
          </p>
        )}
      </div>
    </div>
  );
};
