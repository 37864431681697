import axios from "axios";

export function LoginService(data) {
  return axios({
    method: "post",
    url: "https://navidadalacolombina.com/bo/ep_login/index.php",
    validateStatus: null,
    data: {
      email: data.email,
      cedula: data.cedula,
    },
  });
}

export function RegisterService(data) {
  return axios({
    method: "post",
    url: "https://navidadalacolombina.com/bo/ep_add_cliente/index.php",
    validateStatus: null,
    data: {
      nombres: data.names,
      email: data.email,
      genero: data.gender,
      edad: data.age,
      acepto: data.acceptTerms ? "S" : "N",
      id_pais: data.countryCode,
      cedula: data.documentNumber,
    },
  });
}
