import React, { useState, useEffect } from "react";
import "./styles/main.scss";
import "./App.css";
import AppRoutes from "./routes/index";
import { IntlProvider } from "react-intl";
import { LOCALES } from "./i8n/locales";
import messages from "./i8n/messages";

import { MuiPickersUtilsProvider } from "@material-ui/pickers";

// pick a date util library
import MomentUtils from "@date-io/moment";
import { useLocation } from "react-router-dom";
import ReactGA from "react-ga4";

function App() {
  const [language, setLanguage] = useState(LOCALES.SPANISH);
  const path = useLocation().pathname;
  const donde = path.split("/");

  useEffect(() => {
    console.log(donde[1]);
    if (
      donde[1] === "USA" ||
      donde[1] === "PUERTO_RICO" ||
      donde[1] === "JAMAICA" ||
      donde[1] === "TRINIDAD_TOBAGO" ||
      donde[1] === "GUYANAS" ||
      donde[1] === "SURINAM"
    ) {
      setLanguage(LOCALES.ENGLISH);
    } else if (donde[1]) {
      setLanguage(LOCALES.SPANISH);
    }
  }, [donde[1]]);

  const TRACKING_ID = "G-FLM9WPDLDP";
  ReactGA.initialize(TRACKING_ID);
  return (
    <div className="App">
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <IntlProvider locale={language} messages={messages[language]}>
          <AppRoutes setLanguage={setLanguage} lang={language} />
        </IntlProvider>
      </MuiPickersUtilsProvider>
    </div>
  );
}

export default App;
