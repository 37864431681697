import { LOCALES } from "../locales";

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  [LOCALES.ENGLISH]: {
    navbarHome: "Home",
    navbarAbout: "About",
    lan: "English",
    registerButton: "Create a Christmas card",
    registerTitle: "LOGIN TO YOUR ACCOUNT",
    email: "EMAIL ADDRESS",
    password: "PASSWORD",
    confirmPass: "CONFIRM PASSWORD",
    names: "Names",
    country: "Country",
    lastNames: "LAST NAME",
    createAccount: "CREATE ACCOUNT",
    documentNumber: "I.D.",
    login: "Log in",
    dont: "Need an account?",
    create: "Sign up",
    send: "Register now",
    email: "Email",
    birthday: "BIRTHDATE",
    gender: "Gender",
    male: "MALE",
    age: "Age",
    catalog: "Products",
    female: "FEMALE",
    other: "OTHER",
    shareFamily: "Share joyful moments with your family",
    experience: "Experience Christmas by browsing our holiday catalogue",
    wrongPass: "Wrong email or document",
    notRegister: "Email is not registered",

    colombianTerms:
      "Are you in Cali, Bogotá, Medellín or Barranquilla? Buy your cookies in our online store",
    terms: "I have read and agree to Colombina's ",
    privacy: "Privacy policy ",
    termsConditions: "Terms of Service ",
    termsData: "and Data Management Agreement",
    endTerms: " ",
    alreadyRegister: "The email or document number is already registered",

    personalizeCard: "Cutomize your E-Card",
    loadPicture: "UPLOAD IMAGE",
    replacePicture: "REPLACE IMAGE",
    letsStart: "Lets Start!",
    background: "Background",
    text: "Text",
    characters: "Characters",
    stickers: "Stickers",
    xmasMessage: "Christmas Message",
    remitent: "From",
    destinatary: "To",
    saveShare: " Save / Share",
    wellDone: "Well done!",
    download: "Download",
    share: "Share",
    browsing: "Continue browsing",

    back: "Back to login",
    sendW: "Send",
    aditional: "Aditional text",
    createFirst: "Oops... Please create a card first",

    extraHello: "Hello ",
    extraMsj:
      " you just recieved a message full of flavor that brings you closer to christmas,",
    extraDe: " from ",
    extraClose:
      " If you want to send your own card to your loved ones, enter this link ",

    createCard: "Create card",
    startOver: "Create a New Card",
    readyToSend: "Ready To Send!",
    personalizeIt: "Customize it with your favorite image!",
    predisenadas: "Predesigned",
    textura: "Personalized",
    xmasMessageResp: "Message",
  },
};
