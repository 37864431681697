import React, { useEffect, useState } from "react";
import { Button, Col, Row } from "react-bootstrap";

import Flavor from "../assets/2024/home/EL-SABOR-QUE-TE-ACERCA-A-LA-NAVIDAD.png";
import FlavorEn from "../assets/2024/home/TITULO_INGLES.png";

import { Link, useLocation, useNavigate } from "react-router-dom";
import { useIntl } from "react-intl";
import { BlueSection } from "../components/components/sharedSections/blueSection";
import { RedSection } from "../components/components/sharedSections/redSection";

import MainText from "../assets/2024/startOptions/TEXTO-SUPERIOR.png";
import MainTextEn from "../assets/2024/startOptions/TEXTO-SUPERIOR-EN.png";

import Tarjeta from "../assets/2024/startOptions/TARJET.gif";
import Stickers from "../assets/2024/startOptions/STICKER.gif";
import Diviertete from "../assets/2024/startOptions/JUEGA.gif";
import TarjetaEN from "../assets/2024/startOptions/TARJET-EN.gif";
import StickersEN from "../assets/2024/startOptions/STICKER-EN.gif";
import DivierteteEN from "../assets/2024/startOptions/JUEGA-EN.gif";

import Recetas from "../assets/2024/startOptions/RECETAS.gif";
import Novena from "../assets/2024/startOptions/NOVENA.gif";
import Catalogo from "../assets/2024/startOptions/CATALOGO.gif";
import RecetasEN from "../assets/2024/startOptions/RECETAS-EN.gif";
import CatalogoEN from "../assets/2024/startOptions/CATALOGO-EN.gif";
import Posada from "../assets/2024/startOptions/POSADA.gif";
import NovenaDoc from "../assets/2024/startOptions/NOVENA_NAV_2024.pdf";
import PosadaDoc from "../assets/2024/startOptions/POSADA_NAV_2024.pdf";
import { ModalLoginRegister } from "../components/components/modalLoginRegister";
import useAnalyticsEventTracker from "../services/useAnalyticsEventTracker";
export const StartOptions = () => {
  const gaEvent = useAnalyticsEventTracker("Pagina lets start");
  const path = useLocation().pathname;
  const donde = path.split("/")[1];
  const intl = useIntl();
  const [showModalRegister, setShowModalRegister] = useState(false);
  const [modalLoginUrl, setModalLoginUrl] = useState("");
  const navigate = useNavigate();
  const paises = [
    "ECUADOR",
    "PERU",
    "COLOMBIA",
    "COSTA_RICA",
    "GUATEMALA",
    "SALVADOR",
    "HONDURAS",
    "PANAMA",
    "VENEZUELA",
    "BOLIVIA",
    "USA",
    "PUERTO_RICO",
    "GUYANAS",
    "JAMAICA",
    "SURINAM",
    "REPUBLICA_DOMINICANA",
    "TRINIDAD_TOBAGO",
  ];

  const handleClickLoginValidation = (link) => {
    setModalLoginUrl(link);
    if (localStorage.getItem("isLogged")) {
      navigate(link);
    } else {
      setShowModalRegister(true);
    }
  };

  useEffect(() => {
    if ("scrollRestoration" in window.history) {
      window.history.scrollRestoration = "manual";
    }

    window.scrollTo(0, 0); // Scroll to top

    return () => {
      if ("scrollRestoration" in window.history) {
        window.history.scrollRestoration = "auto";
      }
    };
  }, []);

  return (
    <div className="startPage">
      <ModalLoginRegister
        show={showModalRegister}
        setShow={setShowModalRegister}
        language={intl.locale}
        redirectUrl={modalLoginUrl}
      />
      <Row className="startOptions__firstRow">
        <Row className="startOptions__container justify-content-center">
          <Col xs={12}>
            <img
              src={intl.locale.includes("es") ? Flavor : FlavorEn}
              className="startPage__flavor__image"
              alt="Letrero el sabor que te acerca"
            />
          </Col>
          <Col xs={12}>
            <img
              src={intl.locale.includes("es") ? MainText : MainTextEn}
              alt="Texto crea tus tarjetas"
              className="startOptions__text"
            />
          </Col>
          <Row className="justify-content-center mt-4 mb-3">
            <Col xs={4} lg={3}>
              <img
                onClick={() => {
                  handleClickLoginValidation(`/${donde}/christmascard`);
                }}
                src={intl.locale.includes("es") ? Tarjeta : TarjetaEN}
                alt="Boton tarjetas"
                className="w-100 max-width-custom max-height-custom-responsive max-width-custom-responsive cursor__pointer"
              />
            </Col>
            <Col xs={4} lg={3}>
              <img
                onClick={() => {
                  handleClickLoginValidation(`/${donde}/stickers`);
                }}
                src={intl.locale.includes("es") ? Stickers : StickersEN}
                alt="Boton Stickers"
                className="w-100 max-width-custom max-height-custom-responsive max-width-custom-responsive cursor__pointer"
              />
            </Col>
            <Col xs={4} lg={3}>
              <Link to={`/${donde}/fun`}>
                <img
                  src={intl.locale.includes("es") ? Diviertete : DivierteteEN}
                  alt="Boton diviertete"
                  className="w-100 max-width-custom max-height-custom-responsive max-width-custom-responsive"
                />
              </Link>
            </Col>
          </Row>
          <Row
            className="justify-content-center align-items-center mt-5"
            style={{ zIndex: "6", position: "relative" }}
          >
            <Col xs={4} lg={3}>
              {" "}
              <Link to={`/${donde}/recipes`}>
                <img
                  src={intl.locale.includes("es") ? Recetas : RecetasEN}
                  alt="Boton Recetas"
                  className=" startOptions__imageButton"
                />
              </Link>
            </Col>
            {donde !== "USA" &&
              donde !== "PUERTO_RICO" &&
              donde !== "JAMAICA" &&
              donde !== "TRINIDAD_TOBAGO" &&
              donde !== "JAMAICA" &&
              donde !== "GUYANAS" &&
              donde !== "SURINAM" && (
                <Col xs={4} lg={3}>
                  <a
                    href={donde === "GUATEMALA" ? PosadaDoc : NovenaDoc}
                    download={donde === "GUATEMALA" ? "Posada" : "Novena"}
                    target="_blank"
                    rel="noreferrer"
                    onClick={() => {
                      gaEvent("Descarga novena " + donde);
                    }}
                  >
                    <img
                      src={donde === "GUATEMALA" ? Posada : Novena}
                      alt="Boton Novena"
                      className=" startOptions__imageButton"
                    />
                  </a>
                </Col>
              )}
            <Col xs={4} lg={3}>
              {" "}
              <a
                href={
                  donde == "COLOMBIA"
                    ? require("../assets/2024/catalogs/CAT_NAV_2024_COLOMBIA.pdf")
                    : donde == "VENEZUELA"
                    ? require("../assets/2024/catalogs/CAT_NAV_2024_VENEZUELA.pdf")
                    : donde == "ECUADOR"
                    ? require("../assets/2024/catalogs/CAT_NAV_2024_ECUADOR.pdf")
                    : donde == "USA"
                    ? require("../assets/2024/catalogs/CAT_NAV_2024_USA.pdf")
                    : donde == "PERU"
                    ? require("../assets/2024/catalogs/CAT_NAV_2024_PERU.pdf")
                    : donde == "GUATEMALA" ||
                      donde == "PANAMA" ||
                      donde == "SALVADOR" ||
                      donde == "COSTA_RICA" ||
                      donde == "HONDURAS"
                    ? require("../assets/2024/catalogs/CAT_NAV_2024_CAM.pdf")
                    : donde == "JAMAICA" ||
                      donde == "GUYANAS" ||
                      donde == "SURINAM" ||
                      donde == "TRINIDAD_TOBAGO" ||
                      donde == "PUERTO_RICO" ||
                      donde == "REPUBLICA_DOMINICANA"
                    ? require("../assets/2024/catalogs/CAT_NAV_2024_CARIBE.pdf")
                    : require("../assets/2024/catalogs/CAT_NAV_2024_COLOMBIA.pdf")
                }
                download={
                  paises.includes(donde)
                    ? intl.locale.includes("es")
                      ? "Catalogo" + donde
                      : "Catalogue" + donde
                    : "CatalogoColombiano"
                }
                onClick={() => {
                  gaEvent("Descarga catalogo " + donde);
                }}
              >
                <img
                  src={intl.locale.includes("es") ? Catalogo : CatalogoEN}
                  alt="Boton Catalogo"
                  className="startOptions__imageButton"
                />
              </a>
            </Col>
          </Row>
        </Row>
      </Row>
      <BlueSection />
      <RedSection />
    </div>
  );
};
