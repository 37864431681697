import React, { useState } from "react";
import { Button, Col, Row } from "react-bootstrap";

import Flavor from "../assets/2024/home/EL-SABOR-QUE-TE-ACERCA-A-LA-NAVIDAD.png";
import FlavorEn from "../assets/2024/home/TITULO_INGLES.png";

import Explora from "../assets/2024/startPage/EXPLORA-ESPANOL.png";
import ExploraEn from "../assets/2024/startPage/EXPLORA-INGLES.png";

import Start from "../assets/2024/startPage/COMENZAR-BOTON.png";
import StartEn from "../assets/2024/startPage/COMENZAR-BOTON-EN.png";

import Arbol from "../assets/2024/startPage/ARBOL-DE-NAVIDAD-CON-NOMO.png";

import {
  Link,
  NavLink,
  useParams,
  useLocation,
  useNavigate,
} from "react-router-dom";
import { useIntl } from "react-intl";
import { BlueSection } from "../components/components/sharedSections/blueSection";
import { RedSection } from "../components/components/sharedSections/redSection";

export const StartPage = () => {
  const path = useLocation().pathname;
  const donde = path.split("/")[1];
  const intl = useIntl();
  const [showModalRegister, setShowModalRegister] = useState(false);
  const navigate = useNavigate();

  return (
    <div className="startPage">
      {/* <ModalLoginRegister show={showModalRegister} language={intl.locale} /> */}
      <Row className="startPage__flavor">
        <Row className="startPage__flavor__container justify-content-center">
          <img
            src={intl.locale.includes("es") ? Flavor : FlavorEn}
            className="startPage__flavor__image"
            alt="Letrero el sabor que te acerca"
          />
        </Row>
        <Row className="justify-content-between">
          <Col xs={5}>
            <img
              src={Arbol}
              className="startPage__flavor__tree"
              alt="Imagen arbol con gnomo"
            />
          </Col>
          <Col xs={6}>
            <Row className="justify-content-center">
              <Col xs={12} className="text-align-start-landscape">
                {" "}
                <img
                  src={intl.locale.includes("es") ? Explora : ExploraEn}
                  className="startPage__flavor__explore"
                  alt="Letrero explora el mundo"
                />
              </Col>

              <Col xs={12} className="mt-3 text-align-start-landscape">
                <Link to={"start"}>
                  <img
                    src={intl.locale.includes("es") ? Start : StartEn}
                    className="startPage__flavor__start"
                    alt="Boton de comienzo"
                  />
                </Link>
              </Col>
            </Row>
          </Col>
        </Row>
      </Row>
      <BlueSection />
      <RedSection />
    </div>
  );
};
