import React, { useEffect, useState } from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import { FormattedMessage, useIntl } from "react-intl";
import Switch from "react-switch";
import { TextCustomButtons } from "./textButton";
import { ColorSelectorResponsive } from "./colorSelectorResponsive";
import { FontSizeSelectorResponsive } from "./fontSizeSelectorResponsive";
import { FontTypeSelectorResponsive } from "./fontTypeSelectorResponsive";

export const CustomTextManagerResponsive = ({
  msjNavidad,
  msjDe,
  msjPara,
  setMsjNavidad,
  setMsjDe,
  setMsjPara,
  showMsjNavidad,
  showMsjDe,
  showMsjPara,
  setShowMsjNavidad,
  setShowMsjDe,
  setShowMsjPara,
  msjNavidadFont,
  setMsjNavidadFont,
  msjNavidadColor,
  setMsjNavidadColor,
  msjNavidadSize,
  setMsjNavidadSize,
  msjDeFont,
  setMsjDeFont,
  msjDeColor,
  setMsjDeColor,
  msjParaFont,
  setMsjParaFont,
  msjParaColor,
  setMsjParaColor,
  msjDeSize,
  setMsjDeSize,
  msjParaSize,
  setMsjParaSize,
}) => {
  const intl = useIntl();
  const [selectedText, setSelectedText] = useState(1);
  const [showFont, setShowFont] = useState(false);
  const [showColor, setShowColor] = useState(false);
  const [showSize, setShowSize] = useState(false);

  useEffect(() => {
    if (msjNavidad.length > 0) {
      setShowMsjNavidad(true);
    } else {
      setShowMsjNavidad(false);
    }
    if (msjDe.length > 0) {
      setShowMsjDe(true);
    } else {
      setShowMsjDe(false);
    }
    if (msjPara.length > 0) {
      setShowMsjPara(true);
    } else {
      setShowMsjPara(false);
    }
  }, [msjNavidad, msjDe, msjPara]);
  return (
    <Container className="christmasCardResponsive__txtInputContainer">
      <Row className="christmasCardResponsive__txtInputContainer__textButtonInputRow">
        <Col xs={2}>
          <Button
            id={selectedText === 1 ? "selected" : ""}
            className="christmasCardResponsive__buttonSelector"
            onClick={() => {
              setSelectedText(1);
              setShowColor(false);
              setShowFont(false);
              setShowSize(false);
            }}
          >
            {intl.formatMessage({ id: "xmasMessageResp" })}
          </Button>
        </Col>
        <Col xs={2}>
          <Button
            id={selectedText === 2 ? "selected" : ""}
            className="christmasCardResponsive__buttonSelector"
            onClick={() => {
              setSelectedText(2);
              setShowColor(false);
              setShowFont(false);
              setShowSize(false);
            }}
          >
            {intl.formatMessage({ id: "remitent" })}
          </Button>
        </Col>
        <Col xs={2}>
          <Button
            id={selectedText === 3 ? "selected" : ""}
            className="christmasCardResponsive__buttonSelector"
            onClick={() => {
              setSelectedText(3);
              setShowColor(false);
              setShowFont(false);
              setShowSize(false);
            }}
          >
            {intl.formatMessage({ id: "destinatary" })}
          </Button>
        </Col>
        <Col xs={6}>
          <Row style={{ height: "100%" }}>
            <Col
              xs={4}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              {selectedText === 1 && (
                <FontSizeSelectorResponsive
                  fontSize={msjNavidadSize}
                  setFontSize={setMsjNavidadSize}
                  setShowSize={setShowSize}
                  show={showSize}
                  setShowFont={setShowFont}
                  setShowColor={setShowColor}
                />
              )}
              {selectedText === 2 && (
                <FontSizeSelectorResponsive
                  fontSize={msjDeSize}
                  setFontSize={setMsjDeSize}
                  setShowSize={setShowSize}
                  show={showSize}
                  setShowFont={setShowFont}
                  setShowColor={setShowColor}
                />
              )}
              {selectedText === 3 && (
                <FontSizeSelectorResponsive
                  fontSize={msjParaSize}
                  setFontSize={setMsjParaSize}
                  setShowSize={setShowSize}
                  show={showSize}
                  setShowFont={setShowFont}
                  setShowColor={setShowColor}
                />
              )}
            </Col>
            <Col
              xs={4}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              {selectedText === 1 && (
                <ColorSelectorResponsive
                  colorId={"msjNavidad-color"}
                  fontColor={msjNavidadColor}
                  setFontColor={setMsjNavidadColor}
                />
              )}
              {selectedText === 2 && (
                <ColorSelectorResponsive
                  colorId={"msjDe-color"}
                  fontColor={msjDeColor}
                  setFontColor={setMsjDeColor}
                />
              )}
              {selectedText === 3 && (
                <ColorSelectorResponsive
                  colorId={"msjPara-color"}
                  fontColor={msjParaColor}
                  setFontColor={setMsjParaColor}
                />
              )}
            </Col>
            <Col
              xs={4}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              {selectedText === 1 && (
                <FontTypeSelectorResponsive
                  fontFamily={msjNavidadFont}
                  setFontFamily={setMsjNavidadFont}
                  show={showFont}
                  setShowFont={setShowFont}
                  setShowSize={setShowSize}
                  setShowColor={setShowColor}
                />
              )}
              {selectedText === 2 && (
                <FontTypeSelectorResponsive
                  fontFamily={msjDeFont}
                  setFontFamily={setMsjDeFont}
                  show={showFont}
                  setShowFont={setShowFont}
                  setShowSize={setShowSize}
                  setShowColor={setShowColor}
                />
              )}
              {selectedText === 3 && (
                <FontTypeSelectorResponsive
                  fontFamily={msjParaFont}
                  setFontFamily={setMsjParaFont}
                  show={showFont}
                  setShowFont={setShowFont}
                  setShowSize={setShowSize}
                  setShowColor={setShowColor}
                />
              )}
            </Col>
          </Row>
        </Col>
      </Row>
      <Row className="christmasCardResponsive__txtInputContainer__textInputRow">
        {selectedText === 1 && (
          <input
            className="christmasCardResponsive__txtInputContainer__textInput"
            type="text"
            value={msjNavidad}
            name="msjNavidad"
            placeholder={intl.formatMessage({ id: "xmasMessage" })}
            autoComplete="off"
            onChange={(e) => setMsjNavidad(e.target.value)}
          ></input>
        )}
        {selectedText === 2 && (
          <input
            className="christmasCardResponsive__txtInputContainer__textInput"
            type="text"
            value={msjDe}
            name="msjDe"
            placeholder={intl.formatMessage({ id: "remitent" })}
            autoComplete="off"
            onChange={(e) => setMsjDe(e.target.value)}
          ></input>
        )}
        {selectedText === 3 && (
          <input
            className="christmasCardResponsive__txtInputContainer__textInput"
            type="text"
            value={msjPara}
            name="msjPara"
            placeholder={intl.formatMessage({ id: "destinatary" })}
            autoComplete="off"
            onChange={(e) => setMsjPara(e.target.value)}
          ></input>
        )}
      </Row>
    </Container>
  );
};
