import React from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import Switch from "react-switch";
import { TextCustomButtons } from "./textButton";

export const CustomTextManager = ({
  msjNavidad,
  msjDe,
  msjPara,
  setMsjNavidad,
  setMsjDe,
  setMsjPara,
  showMsjNavidad,
  showMsjDe,
  showMsjPara,
  setShowMsjNavidad,
  setShowMsjDe,
  setShowMsjPara,
  msjNavidadFont,
  setMsjNavidadFont,
  msjNavidadColor,
  setMsjNavidadColor,
  msjNavidadSize,
  setMsjNavidadSize,
  msjDeFont,
  setMsjDeFont,
  msjDeColor,
  setMsjDeColor,
  msjParaFont,
  setMsjParaFont,
  msjParaColor,
  setMsjParaColor,
  msjDeSize,
  setMsjDeSize,
  msjParaSize,
  setMsjParaSize,
}) => (
  <Container style={{ paddingTop: "5vh", height: "60vh" }}>
    <div className="christmasCard__txtInputDiv">
      <Row>
        <Col xs={6} md={8}>
          <h1>
            <FormattedMessage id="xmasMessage" />
          </h1>
        </Col>
        <Col xs={6} md={4}>
          <Switch
            checked={showMsjNavidad}
            onChange={() => {
              setShowMsjNavidad(!showMsjNavidad);
            }}
            uncheckedIcon={false}
            checkedIcon={false}
            onColor={"#306c44"}
            offColor={"#ded1a7"}
          />
        </Col>
      </Row>
      {showMsjNavidad ? (
        <>
          <Row
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "left",
            }}
          >
            <input
              className="christmasCard__textInput"
              type="text"
              value={msjNavidad}
              name="msjNavidad"
              placeholder="Mensaje Navidad"
              onChange={(e) => setMsjNavidad(e.target.value)}
            ></input>
          </Row>
          <TextCustomButtons
            colorId={"msjNavidad-color"}
            fontColor={msjNavidadColor}
            setFontColor={setMsjNavidadColor}
            fontSize={msjNavidadSize}
            setFontSize={setMsjNavidadSize}
            fontFamily={msjNavidadFont}
            setFontFamily={setMsjNavidadFont}
          />
        </>
      ) : (
        <></>
      )}
    </div>
    <div className="christmasCard__txtInputDiv">
      <Row>
        <Col xs={6} md={8}>
          <h1>
            <FormattedMessage id="remitent" />
          </h1>
        </Col>
        <Col xs={6} md={4}>
          <Switch
            checked={showMsjDe}
            onChange={() => {
              setShowMsjDe(!showMsjDe);
            }}
            uncheckedIcon={false}
            checkedIcon={false}
            onColor={"#306c44"}
            offColor={"#ded1a7"}
          />
        </Col>
      </Row>
      {showMsjDe ? (
        <>
          <Row
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "left",
            }}
          >
            <input
              className="christmasCard__textInput"
              type="text"
              value={msjDe}
              name="msjDe"
              placeholder="De"
              onChange={(e) => setMsjDe(e.target.value)}
            ></input>
          </Row>
          <TextCustomButtons
            colorId={"msjDe-color"}
            fontColor={msjDeColor}
            setFontColor={setMsjDeColor}
            fontSize={msjDeSize}
            setFontSize={setMsjDeSize}
            fontFamily={msjDeFont}
            setFontFamily={setMsjDeFont}
          />
        </>
      ) : (
        <></>
      )}
    </div>
    <div className="christmasCard__txtInputDiv">
      <Row>
        <Col xs={6} md={8}>
          <h1>
            <FormattedMessage id="destinatary" />
          </h1>
        </Col>
        <Col xs={6} md={4}>
          <Switch
            checked={showMsjPara}
            onChange={() => {
              setShowMsjPara(!showMsjPara);
            }}
            onColor={"#306c44"}
            offColor={"#ded1a7"}
            uncheckedIcon={false}
            checkedIcon={false}
          />
        </Col>
      </Row>
      {showMsjPara ? (
        <>
          <Row
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "left",
            }}
          >
            <input
              className="christmasCard__textInput"
              type="text"
              value={msjPara}
              name="msjPara"
              placeholder="Para"
              onChange={(e) => setMsjPara(e.target.value)}
            ></input>
          </Row>
          <TextCustomButtons
            colorId={"msjPara-color"}
            fontColor={msjParaColor}
            setFontColor={setMsjParaColor}
            fontSize={msjParaSize}
            setFontSize={setMsjParaSize}
            fontFamily={msjParaFont}
            setFontFamily={setMsjParaFont}
            setShowMsj={setShowMsjPara}
          />
        </>
      ) : (
        <></>
      )}
    </div>
  </Container>
);
