import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { MdVolumeOff, MdVolumeUp } from "react-icons/md";
import { useIntl } from "react-intl";
import { Link, useNavigate } from "react-router-dom";
import cascaNueces from "../assets/2024/home/CASCANUECES-AZUL.png";
import copoNievePequeno from "../assets/2024/home/COPO-DE-NIEVE-PEQUENO.png";
import divider from "../assets/2024/home/DIVIDER.gif";
import saborNavidad from "../assets/2024/home/EL-SABOR-QUE-TE-ACERCA-A-LA-NAVIDAD.png";
import eligePaisEs from "../assets/2024/home/ELIGE-TU-PAIS-BOTON.png";
import eligePaisEn from "../assets/2024/home/ELIGE-TU-PAIS-INGLES.png";
import galletasNavidad from "../assets/2024/home/GALLETAS-Y-LETRERO-ES.gif";
import galletasNavidadEn from "../assets/2024/home/GALLETAS-Y-LETRERO-EN.gif";
import gingerBreadMan from "../assets/2024/home/GINGER-BREAD-MAN.png";
import lucesNavidad from "../assets/2024/home/LUCES-DE-NAVIDAD.gif";
import saborNavidadEn from "../assets/2024/home/TITULO_INGLES.png";

import BARA_VERTICAL from "../assets/2024/navbar/BARA_VERTICAL.png";

import logoEn from "../assets/2024/navbar/LOGO-COL-INGLES.png";
import logoEs from "../assets/2024/navbar/LOGO-COL-ESPANOL.png";
import { LOCALES } from "../i8n/locales";
import { useAudio } from "../hooks/useAudio";
import eng_selected from "../assets/ENGLISH-DORADO.png";
import spanish from "../assets/SPANISH-BLANCO.png";
import spanishSelected from "../assets/esp_yellow.png";

import eng from "../assets/eng.png";
export const Home = ({
  audio,
  setLanguage,
  lang,
  playingPar,
  setPlayingPar,
}) => {
  const [showCountryList, setShowCountryList] = useState(false);

  const navigate = useNavigate();
  const [playing, toggle] = useAudio(audio, playingPar, setPlayingPar);

  function navigateNewScreen(country) {
    navigate("/" + country);
  }

  return (
    <div className="home">
      <Row className="home__container__menuRow">
        <Col xs={3} md={4} className="home__container__menuRow__logoCol">
          <Link to={"/"}>
            <img
              alt="logo"
              className="home__container__menuRow__logoCol__img"
              src={lang === LOCALES.SPANISH ? logoEs : logoEn}
            />
          </Link>
        </Col>
        <Col xs={8} md={4} className="home__container__menuRow__languageCol">
          <Row
            style={{ marginRight: "3%" }}
            className="align-items-center justify-content-center"
          >
            <Col xs={4}>
              <button
                className="botonIdiomas m-0"
                onClick={() => {
                  setLanguage(LOCALES.SPANISH);
                }}
              >
                <img
                  src={lang === LOCALES.SPANISH ? spanishSelected : spanish}
                  alt="market"
                  className="home__container__menuRow__languageCol__languageSelector"
                ></img>
              </button>
            </Col>
            <Col xs={1}>
              <span>
                <img
                  src={BARA_VERTICAL}
                  alt="market"
                  width="auto"
                  height="30px"
                ></img>{" "}
              </span>
            </Col>
            <Col xs={4}>
              <button
                className="botonIdiomas m-0"
                onClick={() => {
                  setLanguage(LOCALES.ENGLISH);
                }}
              >
                <img
                  src={lang === LOCALES.ENGLISH ? eng_selected : eng}
                  alt="market"
                  className="home__container__menuRow__languageCol__languageSelector"
                ></img>
              </button>
            </Col>
            <Col xs={1}>
              <span>
                <img
                  src={BARA_VERTICAL}
                  alt="market"
                  width="auto"
                  height="30px"
                ></img>{" "}
              </span>
            </Col>
            <Col xs={2}>
              <button
                className="botonSonido"
                onClick={toggle}
                style={{ paddingLeft: "2rem" }}
              >
                {playing ? <MdVolumeUp /> : <MdVolumeOff />}
              </button>
            </Col>
          </Row>
        </Col>
      </Row>

      <img alt="divider" src={divider} className="home__divider"></img>
      <img
        alt="nut cracker"
        src={cascaNueces}
        className="home__cascaNueces"
        id={showCountryList ? "showCountryList" : ""}
      ></img>
      <img
        alt="christmas lights"
        src={lucesNavidad}
        className="home__lucesNavidad"
      ></img>

      <img
        alt="cookies"
        className="home__galletasNavidad"
        id={showCountryList ? "showCountryList" : ""}
        src={lang === LOCALES.SPANISH ? galletasNavidad : galletasNavidadEn}
      ></img>

      <img
        src={gingerBreadMan}
        alt={"Ginger bread cookie"}
        className="home__gingerBreadMan"
        id={showCountryList ? "showCountryList" : ""}
      />

      <img
        alt="snowflake"
        className="home__copoNieve1"
        src={copoNievePequeno}
      ></img>
      <img
        alt="snowflake"
        className="home__copoNieve2"
        src={copoNievePequeno}
      ></img>
      <div className="home__redblock"></div>
      <FlagSelector
        setShowCountryList={setShowCountryList}
        showCountryList={showCountryList}
        navigateNewScreen={navigateNewScreen}
      />
    </div>
  );
};

const FlagSelector = ({
  showCountryList,
  setShowCountryList,
  navigateNewScreen,
}) => {
  const intl = useIntl();
  const paises = [
    { code: "COLOMBIA", name: "COLOMBIA" },
    { code: "PERU", name: "PERÚ" },
    { code: "ECUADOR", name: "ECUADOR" },
    { code: "BOLIVIA", name: "BOLIVIA" },
    { code: "VENEZUELA", name: "VENEZUELA" },
    { code: "GUATEMALA", name: "GUATEMALA" },
    { code: "PANAMA", name: "PANAMÁ" },
    { code: "SALVADOR", name: "SALVADOR" },
    { code: "COSTA_RICA", name: "COSTA RICA" },
    { code: "HONDURAS", name: "HONDURAS" },
    { code: "REPUBLICA_DOMINICANA", name: "REPÚBLICA DOMINICANA" },
    { code: "PUERTO_RICO", name: "PUERTO RICO" },
    { code: "JAMAICA", name: "JAMAICA" },
    { code: "GUYANAS", name: "GUYANAS" },
    { code: "SURINAM", name: "SURINAM" },
    { code: "TRINIDAD_TOBAGO", name: "TRINIDAD Y TOBAGO" },
    { code: "USA", name: "ESTADOS UNIDOS" },
  ];
  return (
    <div className="home__flagSelector">
      <Container>
        <Row>
          <img
            alt={"main logo"}
            src={intl.locale.includes("es") ? saborNavidad : saborNavidadEn}
            className="home__flagSelector__titleImage"
          />
        </Row>
        <Row className="home__flagSelector__container__selectRow">
          {intl.locale.includes("es") ? (
            <>
              <img
                src={eligePaisEs}
                className="home__flagSelector__container__selectRow__buttonImage"
                alt="Elige tu pais"
                onClick={() => {
                  setShowCountryList(!showCountryList);
                }}
              />
            </>
          ) : (
            <img
              src={eligePaisEn}
              className="home__flagSelector__container__selectRow__buttonImage"
              alt="logo"
              onClick={() => {
                setShowCountryList(!showCountryList);
              }}
            />
          )}
        </Row>
        {showCountryList && (
          <Row className="home__flagSelector__container__selectRow">
            <Row className="home__flagSelector__container__selectRow__countryList align-items-end ">
              {paises.map((item, index) => {
                return (
                  <Col
                    className="home__flagSelector__container__selectRow__countryList__countryCol"
                    xs={3}
                    key={index}
                    onClick={() => {
                      navigateNewScreen(item.code);
                    }}
                  >
                    <Row>
                      <p className="flagName" style={{ textAlign: "center" }}>
                        {item.name}
                      </p>
                    </Row>
                    <Row className=" justify-responsive">
                      <img
                        src={require("../assets/2024/components/flagSelector/BANDERA_" +
                          item.code +
                          ".png")}
                        className="max-size-images-responsive"
                      />
                    </Row>
                  </Col>
                );
              })}
            </Row>
          </Row>
        )}
      </Container>
    </div>
  );
};
