import axios from "axios";
const qs = require("qs");
export function WhatsappImage(data) {
  return axios({
    method: "POST",

    headers: {
      "content-type": "application/x-www-form-urlencoded",
    },

    data: qs.stringify({
      token: "mdgy351ka7ypzgfh",
      to: data.number,
      image: data.image,
      caption: data.caption,
      referenceId: "",
      nocache: "",
    }),

    url: "https://api.ultramsg.com/instance59819/messages/image",
    validateStatus: null,
  });
}

function urlContentToDataUri(image) {
  switch (image) {
    case "MOOD_NOVENA":
      return "https://halloweencolombina.com/stickers_navidad/S1.webp";
    case "TUTAINA":
      return "https://halloweencolombina.com/stickers_navidad/S2.webp";
    case "QUE_HAY_PA_HOY":
      return "https://halloweencolombina.com/stickers_navidad/S3.webp";
    case "MERRY_XMAS":
      return "https://halloweencolombina.com/stickers_navidad/S4.webp";
    case "LLEGO_DICIEMBRE":
      return "https://halloweencolombina.com/stickers_navidad/S5.webp";
    case "FELIZ_NEW_YEAR":
      return "https://halloweencolombina.com/stickers_navidad/S6.webp";
    case "HOY_ES_NAVIDAD":
      return "https://halloweencolombina.com/stickers_navidad/S7.webp";
    case "FELIZ_NAVIDAD_GINGERBREAD":
      return "https://halloweencolombina.com/stickers_navidad/S8.webp";
    case "WHATS_UP_TODAY":
      return "https://halloweencolombina.com/stickers_navidad/S1Eng.webp";
    case "TODAY_IS_CHRISTMAS":
      return "https://halloweencolombina.com/stickers_navidad/S2Eng.webp";
    case "MERRY_CHRISTMAS_GINGERBREAD":
      return "https://halloweencolombina.com/stickers_navidad/S4Eng.webp";
    case "HAPPY_NEW_YEAR":
      return "https://halloweencolombina.com/stickers_navidad/S5Eng.webp";
    case "DECEMBER_IS_HERE":
      return "https://halloweencolombina.com/stickers_navidad/S6Eng.webp";
  }
}
export async function WhatsappSticker(extraData) {
  let stickerUrl = urlContentToDataUri(extraData.img);
  const data = qs.stringify({
    token: "mdgy351ka7ypzgfh",
    to: `${extraData.to}`,
    sticker: stickerUrl,
  });
  const data2 = qs.stringify({
    token: "mdgy351ka7ypzgfh",
    to: `+573183663560`,
    body: "La API de WhatsApp en UltraMsg.com funciona bien",
  });

  var config2 = {
    method: "post",
    url: "https://api.ultramsg.com/instance59819/messages/chat",
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
    data: data2,
  };

  const config = {
    method: "post",
    url: "https://api.ultramsg.com/instance59819/messages/sticker",
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
    data: data,
  };
  axios(config2);
  return axios(config);
}
