import React, { useEffect, useRef, useState } from "react";
import { Button, Col, Container, Row, Tab, Tabs } from "react-bootstrap";
import { BiImageAdd } from "react-icons/bi";
import useImage from "use-image";
import personalizaTuTarjeta from "../assets/2024/christmasCard/PERSONALIZA-TU-TARJETA.png";
import personalizaTuTarjetaEn from "../assets/2024/christmasCard/PERSONALIZA-TU-TARJETA-EN.png";
import { IconManager } from "../components/components/christmasCard/icons";
import { CanvasStage } from "../components/components/christmasCard/stage";
import { StickerManager } from "../components/components/christmasCard/stickers";
import { Upload } from "../components/components/christmasCard/upload";

import GingerBreadMan from "../assets/2024/fun/MUNECO-DULCE.gif";
import MunecoNieve from "../assets/2024/fun/PINGUINO.gif";
import nieve from "../assets/2024/christmasCard/NIEVE-FONDO.png";

import tituloSabor from "../assets/2024/christmasCard/EL-SABOR-QUE-TE-ACERCA.png";
import tituloSaborEn from "../assets/2024/christmasCard/TITULO_SABORQUETEACA_EN.png";
import { CustomTextManager } from "../components/components/christmasCard/textInput";

//IMPORT IMAGENES PARA PERSONAJES
import cascaNueces from "../assets/2024/christmasCard/avatares/BOTON-CASCANUECES.png";
import cascaNuecesAzul from "../assets/2024/christmasCard/avatares/BOTON-CASCANUECES-AZUL.png";
import gnomo from "../assets/2024/christmasCard/avatares/BOTON-NOMO.png";
import gingerBread from "../assets/2024/christmasCard/avatares/GINGER_BREAD_ICON.png";
import munecoNieveVerde from "../assets/2024/christmasCard/avatares/MUNECO_NIEVE_VERDE.png";
import munecoNieveRojo from "../assets/2024/christmasCard/avatares/MUNECO_NIEVE_ROJO.png";
import renoRojo from "../assets/2024/christmasCard/avatares/BOTON-RNO-ROJO.png";
import renoVerde from "../assets/2024/christmasCard/avatares/BOTON-RNO-VERDE.png";

//IMPORT IMAGENES PARA STICKERS
import bolas from "../assets/2024/christmasCard/stickers/STICKER-1.png";
import gafas from "../assets/2024/christmasCard/stickers/STICKER-2.png";
import gorro from "../assets/2024/christmasCard/stickers/STICKER-3.png";
import regalo from "../assets/2024/christmasCard/stickers/STICKER-4.png";

//IMPORT IMAGENES PARA FONDOS
import fondo1ImportEng from "../assets/2024/christmasCard/cardBackgrounds/01-eng.png";
import fondo1Import from "../assets/2024/christmasCard/cardBackgrounds/01.png";
import fondo2ImportEng from "../assets/2024/christmasCard/cardBackgrounds/02-eng.png";
import fondo2Import from "../assets/2024/christmasCard/cardBackgrounds/02.png";
import fondo3ImportEng from "../assets/2024/christmasCard/cardBackgrounds/03-eng.png";
import fondo3Import from "../assets/2024/christmasCard/cardBackgrounds/03.png";
import fondo4ImportEng from "../assets/2024/christmasCard/cardBackgrounds/04-eng.png";
import fondo4Import from "../assets/2024/christmasCard/cardBackgrounds/04.png";
import fondo1ImportPre from "../assets/2024/christmasCard/cardBackgrounds/pre-01.png";
import fondo2ImportPre from "../assets/2024/christmasCard/cardBackgrounds/pre-02.png";
import fondo3ImportPre from "../assets/2024/christmasCard/cardBackgrounds/pre-03.png";
import fondo4ImportPre from "../assets/2024/christmasCard/cardBackgrounds/pre-04.png";
import fondo1ImportPreEng from "../assets/2024/christmasCard/cardBackgrounds/pre-eng-01.png";
import fondo2ImportPreEng from "../assets/2024/christmasCard/cardBackgrounds/pre-eng-02.png";
import fondo3ImportPreEng from "../assets/2024/christmasCard/cardBackgrounds/pre-eng-03.png";
import fondo4ImportPreEng from "../assets/2024/christmasCard/cardBackgrounds/pre-eng-04.png";

import { FormattedMessage, useIntl } from "react-intl";
import { BackGroundManager } from "../components/components/christmasCard/backgroundSelector";
import { ModalCompartir } from "../components/components/modalCompartir";
import { BackGroundManagerResponsive } from "../components/components/christmasCard/backgroundSelectorResponsive";
import { CustomTextManagerResponsive } from "../components/components/christmasCard/textInputResponsive";

export const ChristmasCard = ({ language }) => {
  const [width, setWidth] = useState(100);
  const [height, setHeight] = useState(100);
  const [widthResponsive, setWidthResponsive] = useState(0);
  const [heightResponsive, setHeightResponsive] = useState(0);
  const intl = useIntl();

  const imageRef = useRef();
  const imageRefResp = useRef();
  //HOOK PARA MANEJO DE TABS RESPONSIVE
  const [selectedTab, setSelectedTab] = useState(1);

  //HOOK PARA MANEJO DE IMAGEN DEL USUARIO
  const [showImage, setShowImage] = useState(false);
  const [uploadedImage, setUploadedImage] = useState();
  const [image] = useImage(uploadedImage, "Anonymous");
  const [imgHeight, setImgHeight] = useState(100);
  const [imgWidth, setImgWidth] = useState(100);
  const [imgX, setImgX] = useState(10);
  const [imgY, setImgY] = useState(100);

  //HOOKS PARA EL MANEJO DE LOS PERSONAJES
  const [showZorro, setShowZorro] = useState(false);
  const [showIcon1, setShowIcon1] = useState(false);
  const [showIcon3, setShowIcon3] = useState(false);
  const [showIcon4, setShowIcon4] = useState(false);
  const [showIcon5, setShowIcon5] = useState(false);
  const [showIcon6, setShowIcon6] = useState(false);
  const [showIcon7, setShowIcon7] = useState(false);
  const [showIcon8, setShowIcon8] = useState(false);

  const [icon2] = useImage(gnomo, "Anonymous");
  const [icon3] = useImage(gingerBread, "Anonymous");
  const [icon1] = useImage(munecoNieveVerde, "Anonymous");
  const [icon4] = useImage(cascaNueces, "Anonymous");
  const [icon5] = useImage(renoRojo, "Anonymous");
  const [icon6] = useImage(renoVerde, "Anonymous");
  const [icon7] = useImage(cascaNuecesAzul, "Anonymous");
  const [icon8] = useImage(munecoNieveRojo, "Anonymous");

  const [personajeX, setPersonajeX] = useState(0);
  const [personjeY, setPersonajeY] = useState(25);

  //HOOKS PARA EL MANEJO DE LOS STICKERS
  const [showBolas, setShowBolas] = useState(false);
  const [showGafas, setshowGafas] = useState(false);
  const [showGorro, setshowGorro] = useState(false);
  const [showRegalo, setshowRegalo] = useState(false);
  const [bolasImg] = useImage(bolas, "Anonymous");
  const [gafasImg] = useImage(gafas, "Anonymous");
  const [gorroImg] = useImage(gorro, "Anonymous");
  const [regaloImg] = useImage(regalo, "Anonymous");
  const [stickerX, setStickerX] = useState(0);
  const [stickerY, setStickery] = useState(150);

  //HOOKS PARA MANEJO DE TEXTOS DE LA TARJETA
  const [msjNavidad, setMsjNavidad] = useState("");
  const [showMsjNavidad, setShowMsjNavidad] = useState(false);
  const [msjNavidadFont, setMsjNavidadFont] = useState("arial");
  const [msjNavidadColor, setMsjNavidadColor] = useState("#000000");
  const [msjNavidadSize, setMsjNavidadSize] = useState(15);
  const [msjNavidadX, setMsjNavidadX] = useState(100);
  const [msjNavidadY, setMsjNavidadY] = useState(300);

  const [msjDe, setMsjDe] = useState("");
  const [showMsjDe, setShowMsjDe] = useState(false);
  const [msjDeColor, setMsjDeColor] = useState("#000000");
  const [msjDeSize, setMsjDeSize] = useState(14);
  const [msjDeFont, setMsjDeFont] = useState("arial");
  const [msjDeX, setMsjDedX] = useState(150);
  const [msjDeY, setMsjDeY] = useState(400);

  const [msjPara, setMsjPara] = useState("");
  const [showMsjPara, setShowMsjPara] = useState(false);
  const [msjParaColor, setMsjParaColor] = useState("#000000");
  const [msjParaSize, setMsjParaSize] = useState(14);
  const [msjParaFont, setMsjParaFont] = useState("arial");
  const [msjParaX, setMsjParaX] = useState(50);
  const [msjParaY, setMsjParaY] = useState(450);

  //HOOKS PARA EL MANEJOS DE LOS FONDOS
  const [indexFondo, setindexFondo] = useState(1);
  const [fondo1] = useImage(fondo1Import, "Anonymous");
  const [fondo1Eng] = useImage(fondo1ImportEng, "Anonymous");
  const [fondo2] = useImage(fondo2Import, "Anonymous");
  const [fondo2Eng] = useImage(fondo2ImportEng, "Anonymous");
  const [fondo3] = useImage(fondo3Import, "Anonymous");
  const [fondo3Eng] = useImage(fondo3ImportEng, "Anonymous");
  const [fondo4] = useImage(fondo4Import, "Anonymous");
  const [fondo4Eng] = useImage(fondo4ImportEng, "Anonymous");

  //HOOKS PARA MANEJO DE FONDOS PREDETERMINADOS
  const [fondo1Pre] = useImage(fondo1ImportPre, "Anonymous");
  const [fondo2Pre] = useImage(fondo2ImportPre, "Anonymous");
  const [fondo3Pre] = useImage(fondo3ImportPre, "Anonymous");
  const [fondo4Pre] = useImage(fondo4ImportPre, "Anonymous");
  const [fondo1PreEng] = useImage(fondo1ImportPreEng, "Anonymous");
  const [fondo2PreEng] = useImage(fondo2ImportPreEng, "Anonymous");
  const [fondo3PreEng] = useImage(fondo3ImportPreEng, "Anonymous");
  const [fondo4PreEng] = useImage(fondo4ImportPreEng, "Anonymous");

  //HOOK PARA MANEJAR MOSTRAR O NO EL CANVAS
  const [showCanvas, setShowCanvas] = useState(false);
  const [selectedId1, selectShape1] = useState(null);
  const [unselected, setunselected] = useState(true);

  //HOOK PARA manejar imagen de render en el componente de compartir
  const [preRenderImg, setPrerenderImg] = useState(null);
  const [preRenderImgResp, setPrerenderImgResp] = useState(null);

  useEffect(() => {
    if (showCanvas) {
      const resizeObserver = new ResizeObserver((event) => {
        // Depending on the layout, you may need to swap inlineSize with blockSize
        // https://developer.mozilla.org/en-US/docs/Web/API/ResizeObserverEntry/contentBoxSize
        setWidth(event[0].contentBoxSize[0].inlineSize);
        setHeight(event[0].contentBoxSize[0].blockSize);
      });
      resizeObserver.observe(document.getElementById("divCanvas"));

      const resizeObserverResponsive = new ResizeObserver((event) => {
        // Depending on the layout, you may need to swap inlineSize with blockSize
        // https://developer.mozilla.org/en-US/docs/Web/API/ResizeObserverEntry/contentBoxSize
        setWidthResponsive(event[0].contentBoxSize[0].inlineSize);
        setHeightResponsive(event[0].contentBoxSize[0].blockSize);
      });
      resizeObserver.observe(document.getElementById("divCanvas"));
      resizeObserverResponsive.observe(
        document.getElementById("divCanvasResponsive")
      );
    }
  }, [showCanvas]);

  useEffect(() => {
    if (width > 0 && height > 0) {
      if (indexFondo === 1) {
        let newXDe = width * 0.41;
        let newYDe = height * 0.65;
        setMsjDedX(newXDe);
        setMsjDeY(newYDe);
        let newXPara = width * 0.46;
        let newYPara = height * 0.69;
        setMsjParaX(newXPara);
        setMsjParaY(newYPara);
      } else if (indexFondo === 2) {
        let newXDe = width * 0.1;
        let newYDe = height * 0.84;
        setMsjDedX(newXDe);
        setMsjDeY(newYDe);
        let newXPara = width * 0.46;
        let newYPara = height * 0.84;
        setMsjParaX(newXPara);
        setMsjParaY(newYPara);
      } else if (indexFondo === 3) {
        let newXDe = width * 0.4;
        let newYDe = height * 0.59;
        setMsjDedX(newXDe);
        setMsjDeY(newYDe);
        let newXPara = width * 0.46;
        let newYPara = height * 0.64;
        setMsjParaX(newXPara);
        setMsjParaY(newYPara);
      } else if (indexFondo === 4) {
        let newXDe = width * 0.0001;
        let newYDe = height * 0.8;
        setMsjDedX(newXDe);
        setMsjDeY(newYDe);
        let newXPara = width * 0.1;
        let newYPara = height * 0.84;
        setMsjParaX(newXPara);
        setMsjParaY(newYPara);
      } else if (indexFondo === 5) {
        let newXChristmas = width * 0.2;
        let newYChristmas = height * 0.5;
        setMsjNavidadX(newXChristmas);
        setMsjNavidadY(newYChristmas);
        let newXDe = width * 0.41;
        let newYDe = height * 0.824;
        setMsjDedX(newXDe);
        setMsjDeY(newYDe);
        let newXPara = width * 0.46;
        let newYPara = height * 0.865;
        setMsjParaX(newXPara);
        setMsjParaY(newYPara);
      } else if (indexFondo === 6) {
        let newXChristmas = width * 0.2;
        let newYChristmas = height * 0.5;
        setMsjNavidadX(newXChristmas);
        setMsjNavidadY(newYChristmas);
        let newXDe = width * 0.1;
        let newYDe = height * 0.88;
        setMsjDedX(newXDe);
        setMsjDeY(newYDe);
        let newXPara = width * 0.5;
        let newYPara = height * 0.88;
        setMsjParaX(newXPara);
        setMsjParaY(newYPara);
      } else if (indexFondo == 7) {
        let newXChristmas = width * 0.2;
        let newYChristmas = height * 0.35;
        setMsjNavidadX(newXChristmas);
        setMsjNavidadY(newYChristmas);
        let newXDe = width * 0.45;
        let newYDe = height * 0.59;
        setMsjDedX(newXDe);
        setMsjDeY(newYDe);
        let newXPara = width * 0.45;
        let newYPara = height * 0.64;
        setMsjParaX(newXPara);
        setMsjParaY(newYPara);
      } else if (indexFondo === 8) {
        let newXChristmas = width * 0.2;
        let newYChristmas = height * 0.35;
        setMsjNavidadX(newXChristmas);
        setMsjNavidadY(newYChristmas);
        let newXDe = width * 0.00001;
        let newYDe = height * 0.821;
        setMsjDedX(newXDe);
        setMsjDeY(newYDe);
        let newXPara = width * 0.06;
        let newYPara = height * 0.86;
        setMsjParaX(newXPara);
        setMsjParaY(newYPara);
      }
    } else if (widthResponsive > 0 && heightResponsive > 0) {
      if (indexFondo === 1) {
        let newXDe = widthResponsive * 0.3;
        let newYDe = heightResponsive * 0.635;
        setMsjDedX(newXDe);
        setMsjDeY(newYDe);
        let newXPara = widthResponsive * 0.43;
        let newYPara = heightResponsive * 0.67;
        setMsjParaX(newXPara);
        setMsjParaY(newYPara);
      } else if (indexFondo === 2) {
        let newXDe = widthResponsive * -0.01;
        let newYDe = heightResponsive * 0.82;
        setMsjDedX(newXDe);
        setMsjDeY(newYDe);
        let newXPara = widthResponsive * 0.36;
        let newYPara = heightResponsive * 0.82;
        setMsjParaX(newXPara);
        setMsjParaY(newYPara);
      } else if (indexFondo === 3) {
        let newXDe = widthResponsive * 0.3;
        let newYDe = heightResponsive * 0.57;
        setMsjDedX(newXDe);
        setMsjDeY(newYDe);
        let newXPara = widthResponsive * 0.35;
        let newYPara = heightResponsive * 0.62;
        setMsjParaX(newXPara);
        setMsjParaY(newYPara);
      } else if (indexFondo === 4) {
        let newXDe = widthResponsive * -0.1;
        let newYDe = heightResponsive * 0.78;
        setMsjDedX(newXDe);
        setMsjDeY(newYDe);
        let newXPara = width * 0.01;
        let newYPara = heightResponsive * 0.83;
        setMsjParaX(newXPara);
        setMsjParaY(newYPara);
      } else if (indexFondo === 5) {
        let newXChristmas = widthResponsive * 0.15;
        let newYChristmas = heightResponsive * 0.5;
        setMsjNavidadX(newXChristmas);
        setMsjNavidadY(newYChristmas);
        let newXDe = widthResponsive * 0.3;
        let newYDe = heightResponsive * 0.81;
        setMsjDedX(newXDe);
        setMsjDeY(newYDe);
        let newXPara = widthResponsive * 0.4;
        let newYPara = heightResponsive * 0.85;
        setMsjParaX(newXPara);
        setMsjParaY(newYPara);
      } else if (indexFondo === 6) {
        let newXChristmas = widthResponsive * 0.15;
        let newYChristmas = heightResponsive * 0.5;
        setMsjNavidadX(newXChristmas);
        setMsjNavidadY(newYChristmas);
        let newXDe = widthResponsive * -0.1;
        let newYDe = heightResponsive * 0.86;
        setMsjDedX(newXDe);
        setMsjDeY(newYDe);
        let newXPara = widthResponsive * 0.35;
        let newYPara = heightResponsive * 0.86;
        setMsjParaX(newXPara);
        setMsjParaY(newYPara);
      } else if (indexFondo == 7) {
        let newXChristmas = widthResponsive * 0.1;
        let newYChristmas = heightResponsive * 0.35;
        setMsjNavidadX(newXChristmas);
        setMsjNavidadY(newYChristmas);
        let newXDe = widthResponsive * 0.45;
        let newYDe = heightResponsive * 0.58;
        setMsjDedX(newXDe);
        setMsjDeY(newYDe);
        let newXPara = widthResponsive * 0.45;
        let newYPara = heightResponsive * 0.64;
        setMsjParaX(newXPara);
        setMsjParaY(newYPara);
      } else if (indexFondo === 8) {
        let newXChristmas = widthResponsive * 0.2;
        let newYChristmas = heightResponsive * 0.35;
        setMsjNavidadX(newXChristmas);
        setMsjNavidadY(newYChristmas);
        let newXDe = widthResponsive * -0.1;
        let newYDe = heightResponsive * 0.811;
        setMsjDedX(newXDe);
        setMsjDeY(newYDe);
        let newXPara = widthResponsive * 0.0;
        let newYPara = heightResponsive * 0.85;
        setMsjParaX(newXPara);
        setMsjParaY(newYPara);
      }
    }
  }, [width, widthResponsive, height, heightResponsive, indexFondo]);
  useEffect(() => {
    if (language.includes("en") && msjDe === "Remitente") {
      setMsjDe("From");
    }
    if (language.includes("es") && msjDe === "From") {
      setMsjDe("Remitente");
    }
    if (language.includes("en") && msjPara === "Destinatario") {
      setMsjPara("To");
    }
    if (language.includes("es") && msjDe === "To") {
      setMsjPara("Destinatario");
    }
    if (language.includes("en") && msjNavidad === "Mensaje de Navidad") {
      setMsjNavidad("Christmas Message");
    }
    if (language.includes("es") && msjDe === "Christmas Message") {
      setMsjNavidad("Mensaje de Navidad");
    }
  }, [language]);

  //FUCION QUE RETORNA EL FONDO DEL CANVA CON BASE EN EL INDEX SELECCIONADO
  function returnFondo(indice) {
    switch (indice) {
      case 1:
        return language.includes("es") ? fondo1Pre : fondo1PreEng;
      case 2:
        return language.includes("es") ? fondo2Pre : fondo2PreEng;
      case 3:
        return language.includes("es") ? fondo3Pre : fondo3PreEng;
      case 4:
        return language.includes("es") ? fondo4Pre : fondo4PreEng;
      case 5:
        return language.includes("es") ? fondo1 : fondo1Eng;
      case 6:
        return language.includes("es") ? fondo2 : fondo2Eng;
      case 7:
        return language.includes("es") ? fondo3 : fondo3Eng;
      case 8:
        return language.includes("es") ? fondo4 : fondo4Eng;
      default:
        return null;
    }
  }

  //METODOS PARA DESCARGA DE TARJETA
  const downloadURI = (uri, name) => {
    const link = document.createElement("a");
    link.download = name;
    link.href = uri;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const handleDownload = () => {
    const dataURL = imageRef.current.toDataURL({ pixelRatio: 4 });
    downloadURI(dataURL, "ChristmasCard.png");
  };

  function getUri() {
    selectShape1(null);
    setTimeout(function () {
      imageRef.current.toImage({
        callback(img) {
          setPrerenderImg(img);
        },
        pixelRatio: 4,
      });
    }, 0);
  }
  const handleDownloadResp = () => {
    const dataURL = imageRefResp.current.toDataURL({ pixelRatio: 4 });
    downloadURI(dataURL, "ChristmasCard.png");
  };

  function getUriResp() {
    selectShape1(null);
    setTimeout(function () {
      imageRefResp.current.toImage({
        callback(img) {
          setPrerenderImgResp(img);
        },
        pixelRatio: 4,
      });
    }, 0);
  }
  function startOver() {
    setindexFondo(1);
    setShowZorro(false);
    setShowIcon1(false);
    setShowIcon3(false);
    setShowIcon4(false);
    setShowIcon5(false);
    setShowIcon6(false);
    setShowIcon7(false);
    setShowIcon8(false);
    setShowMsjDe(false);
    setShowMsjPara(false);
    setShowMsjNavidad(false);
    setShowBolas(false);
    setshowGafas(false);
    setshowGorro(false);
    setshowRegalo(false);
    setShowImage(false);
    if (widthResponsive > 0 && heightResponsive > 0) {
      setMsjNavidad("");
      setMsjDe("");
      setMsjPara("");
    } else {
      setMsjNavidad("Mensaje de Navidad");
      setMsjDe("Remitente");
      setMsjPara("Destinatario");
    }
  }

  useEffect(() => {
    if ("scrollRestoration" in window.history) {
      window.history.scrollRestoration = "manual";
    }

    window.scrollTo(0, 0); // Scroll to top

    return () => {
      if ("scrollRestoration" in window.history) {
        window.history.scrollRestoration = "auto";
      }
    };
  }, []);

  return (
    <>
      <div className="christmasCard hide__responsive">
        <img
          src={nieve}
          alr="Imagen fondo nieve"
          className="sectionBg__nieve"
        />
        <img
          src={MunecoNieve}
          className=" sectionBg__munecoNieve"
          alt="logo"
        ></img>
        <img
          src={GingerBreadMan}
          className="sectionBg__gingerBreadMan"
          alt="logo"
        ></img>

        <div className="christmasCard__mainDiv">
          <Container className="christmasCard__mainContainer">
            <Row className="christmasCard__mainRow">
              <Col xs={12} sm={7} style={{ zIndex: 4 }}>
                <Row>
                  <div className="christmasCard__titleDiv">
                    <img
                      src={
                        language.includes("es") ? tituloSabor : tituloSaborEn
                      }
                      alt="eCard icon"
                      className="christmasCard__tituloImage"
                    ></img>
                  </div>
                </Row>
                <Row
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <img
                    src={
                      language.includes("es")
                        ? personalizaTuTarjeta
                        : personalizaTuTarjetaEn
                    }
                    alt="eCard icon"
                    className="christmasCard__tituloImage"
                  ></img>{" "}
                </Row>
                <Row>
                  <Col xs={{ span: 12 }}>
                    <Tabs
                      defaultActiveKey="fondos"
                      className="mb-4 christmasCard__tabs"
                      fill
                    >
                      <Tab
                        eventKey="fondos"
                        title={intl.formatMessage({ id: "background" })}
                      >
                        <BackGroundManager
                          setIndex={setindexFondo}
                          index={indexFondo}
                          setShowCard={setShowCanvas}
                          language={language}
                        />
                      </Tab>
                      <Tab
                        eventKey="textos"
                        title={intl.formatMessage({ id: "text" })}
                      >
                        <CustomTextManager
                          msjNavidad={msjNavidad}
                          msjDe={msjDe}
                          msjPara={msjPara}
                          setMsjNavidad={setMsjNavidad}
                          setMsjDe={setMsjDe}
                          setMsjPara={setMsjPara}
                          showMsjNavidad={showMsjNavidad}
                          showMsjDe={showMsjDe}
                          showMsjPara={showMsjPara}
                          setShowMsjNavidad={setShowMsjNavidad}
                          setShowMsjDe={setShowMsjDe}
                          setShowMsjPara={setShowMsjPara}
                          msjNavidadColor={msjNavidadColor}
                          setMsjNavidadColor={setMsjNavidadColor}
                          setMsjDeColor={setMsjDeColor}
                          setMsjParaColor={setMsjParaColor}
                          msjNavidadSize={msjNavidadSize}
                          setMsjNavidadSize={setMsjNavidadSize}
                          msjDeSize={msjDeSize}
                          setMsjDeSize={setMsjDeSize}
                          msjParaSize={msjParaSize}
                          setMsjParaSize={setMsjParaSize}
                          msjNavidadFont={msjNavidadFont}
                          setMsjNavidadFont={setMsjNavidadFont}
                          msjDeFont={msjDeFont}
                          setMsjDeFont={setMsjDeFont}
                          msjParaFont={msjParaFont}
                          setMsjParaFont={setMsjParaFont}
                        />
                      </Tab>
                      <Tab
                        eventKey="personajes"
                        title={intl.formatMessage({ id: "characters" })}
                      >
                        <IconManager
                          icon1={showIcon1}
                          setIcon1={setShowIcon1}
                          icon2={showZorro}
                          setIcon2={setShowZorro}
                          icon3={showIcon3}
                          setIcon3={setShowIcon3}
                          icon4={showIcon4}
                          setIcon4={setShowIcon4}
                          icon5={showIcon5}
                          setIcon5={setShowIcon5}
                          icon6={showIcon6}
                          setIcon6={setShowIcon6}
                          icon7={showIcon7}
                          setIcon7={setShowIcon7}
                          icon8={showIcon8}
                          setIcon8={setShowIcon8}
                        />
                      </Tab>
                      <Tab
                        eventKey="stickers"
                        title={intl.formatMessage({ id: "stickers" })}
                      >
                        <StickerManager
                          bolas={showBolas}
                          setBolas={setShowBolas}
                          gafas={showGafas}
                          setGafas={setshowGafas}
                          gorro={showGorro}
                          setGorro={setshowGorro}
                          regalo={showRegalo}
                          setRegalo={setshowRegalo}
                        />
                      </Tab>
                    </Tabs>
                  </Col>
                </Row>
                <Row
                  style={{
                    alignItems: "center",
                    justifyContent: "center",
                    display: "flex",
                  }}
                >
                  <Col
                    xs={12}
                    lg={6}
                    style={{
                      alignItems: "center",
                      justifyContent: "center",
                      display: "flex",
                      padding: "1vh 1vw",
                    }}
                  >
                    <ModalCompartir
                      downloadCanvas={handleDownload}
                      preRenderImg={preRenderImg}
                      getUri={getUri}
                      language={language}
                      showCanvas={showCanvas}
                      de={msjDe}
                      para={msjPara}
                      indiceFondo={indexFondo}
                    />
                  </Col>
                  <Col
                    xs={12}
                    lg={6}
                    style={{
                      alignItems: "center",
                      justifyContent: "center",
                      display: "flex",
                      padding: "1vh 1vw",
                    }}
                  >
                    <Button
                      className="christmasCard__botonInputRestart"
                      onClick={() => {
                        startOver();
                      }}
                    >
                      <FormattedMessage id="startOver" />
                    </Button>
                  </Col>
                </Row>
              </Col>
              <Col xs={12} sm={5} style={{ zIndex: 4 }}>
                <Row
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignContent: "center",
                  }}
                >
                  {showCanvas ? (
                    <div className="christmasCard__stageCanvas" id="divCanvas">
                      <CanvasStage
                        stageRef={imageRef}
                        width={width}
                        heigth={height}
                        image={image}
                        imageHeight={imgHeight}
                        imageWidth={imgWidth}
                        imgX={imgX}
                        imgY={imgY}
                        showImage={showImage}
                        icon2={icon2}
                        showZorro={showZorro}
                        icon3={icon3}
                        showIcon3={showIcon3}
                        icon1={icon1}
                        showIcon1={showIcon1}
                        icon4={icon4}
                        showIcon4={showIcon4}
                        icon5={icon5}
                        showIcon5={showIcon5}
                        icon6={icon6}
                        showIcon6={showIcon6}
                        icon7={icon7}
                        showIcon7={showIcon7}
                        icon8={icon8}
                        showIcon8={showIcon8}
                        personajeX={personajeX}
                        personajeY={personjeY}
                        bolasImg={bolasImg}
                        showBolas={showBolas}
                        gafasImg={gafasImg}
                        showGafas={showGafas}
                        gorroImg={gorroImg}
                        showGorro={showGorro}
                        regaloImg={regaloImg}
                        showRegalo={showRegalo}
                        stickerX={stickerX}
                        stickerY={stickerY}
                        msjNavidad={msjNavidad}
                        msjDe={msjDe}
                        msjPara={msjPara}
                        showMsjNavidad={showMsjNavidad}
                        showMsjDe={showMsjDe}
                        showMsjPara={showMsjPara}
                        fondo={returnFondo(indexFondo)}
                        msjNavidadColor={msjNavidadColor}
                        msjDeColor={msjDeColor}
                        msjParaColor={msjParaColor}
                        msjNavidadSize={msjNavidadSize}
                        msjDeSize={msjDeSize}
                        msjParaSize={msjParaSize}
                        msjNavidadFont={msjNavidadFont}
                        msjDeFont={msjDeFont}
                        msjParaFont={msjParaFont}
                        msjNavidadX={msjNavidadX}
                        msjNavidadY={msjNavidadY}
                        msjParaX={msjParaX}
                        msjParaY={msjParaY}
                        msjDeX={msjDeX}
                        msjDeY={msjDeY}
                        selectedId1={selectedId1}
                        selectShape1={selectShape1}
                      />
                    </div>
                  ) : (
                    <Upload setShowCanvas={setShowCanvas} />
                  )}
                </Row>
                {showCanvas ? (
                  <Row
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <Button className="christmasCard__botonInputReplace">
                      <label
                        htmlFor="contained-button-file"
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          cursor: "pointer",
                        }}
                      >
                        <BiImageAdd style={{ marginRight: "8px" }} />
                        <FormattedMessage id="loadPicture" />
                      </label>
                    </Button>
                    <input
                      type="file"
                      accept="image/*"
                      id="contained-button-file"
                      style={{ display: "none" }}
                      onChange={(e) => {
                        if (e.target.files.length > 0) {
                          setUploadedImage(
                            URL.createObjectURL(e.target.files[0])
                          );
                          const i = new Image();
                          i.onload = function () {
                            const imageDimensions = [
                              {
                                height: i.height,
                                width: i.width,
                              },
                            ];
                            setImgWidth(i.width);
                            setImgHeight(i.height);
                          };

                          i.src = URL.createObjectURL(e.target.files[0]);
                          setShowImage(true);
                          setShowCanvas(true);
                        }
                      }}
                    />
                  </Row>
                ) : (
                  <></>
                )}
              </Col>
            </Row>
          </Container>
        </div>
      </div>

      <div className="christmasCardResponsive show__responsive">
        <Container className="christmasCardResponsive__mainContainer">
          {showCanvas ? (
            <div
              className="christmasCardResponsive__stageCanvas"
              id="divCanvasResponsive"
            >
              <CanvasStage
                stageRef={imageRefResp}
                width={widthResponsive}
                heigth={heightResponsive}
                image={image}
                imageHeight={imgHeight}
                imageWidth={imgWidth}
                imgX={imgX}
                imgY={imgY}
                showImage={showImage}
                icon2={icon2}
                showZorro={showZorro}
                icon3={icon3}
                showIcon3={showIcon3}
                icon1={icon1}
                showIcon1={showIcon1}
                icon4={icon4}
                showIcon4={showIcon4}
                icon5={icon5}
                showIcon5={showIcon5}
                icon6={icon6}
                showIcon6={showIcon6}
                icon7={icon7}
                showIcon7={showIcon7}
                icon8={icon8}
                showIcon8={showIcon8}
                personajeX={personajeX}
                personajeY={personjeY}
                bolasImg={bolasImg}
                showBolas={showBolas}
                gafasImg={gafasImg}
                showGafas={showGafas}
                gorroImg={gorroImg}
                showGorro={showGorro}
                regaloImg={regaloImg}
                showRegalo={showRegalo}
                stickerX={stickerX}
                stickerY={stickerY}
                msjNavidad={msjNavidad}
                msjDe={msjDe}
                msjPara={msjPara}
                showMsjNavidad={showMsjNavidad}
                showMsjDe={showMsjDe}
                showMsjPara={showMsjPara}
                fondo={returnFondo(indexFondo)}
                msjNavidadColor={msjNavidadColor}
                msjDeColor={msjDeColor}
                msjParaColor={msjParaColor}
                msjNavidadSize={msjNavidadSize}
                msjDeSize={msjDeSize}
                msjParaSize={msjParaSize}
                msjNavidadFont={msjNavidadFont}
                msjDeFont={msjDeFont}
                msjParaFont={msjParaFont}
                msjNavidadX={msjNavidadX}
                msjNavidadY={msjNavidadY}
                msjParaX={msjParaX}
                msjParaY={msjParaY}
                msjDeX={msjDeX}
                msjDeY={msjDeY}
                selectedId1={selectedId1}
                selectShape1={selectShape1}
              />
            </div>
          ) : (
            <Upload setShowCanvas={setShowCanvas} />
          )}
          {showCanvas && (
            <Row
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                width: "100%",
                flexDirection: "row",
                marginTop: "2vh",
              }}
            >
              <Col
                xs={6}
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Button className="christmasCard__botonInputReplace">
                  <label
                    htmlFor="contained-button-file"
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      cursor: "pointer",
                    }}
                  >
                    <BiImageAdd style={{ marginRight: "8px" }} />
                    <FormattedMessage id="loadPicture" />
                  </label>
                </Button>
                <input
                  type="file"
                  accept="image/*"
                  id="contained-button-file"
                  style={{ display: "none" }}
                  onChange={(e) => {
                    if (e.target.files.length > 0) {
                      setUploadedImage(URL.createObjectURL(e.target.files[0]));
                      const i = new Image();
                      i.onload = function () {
                        const imageDimensions = [
                          {
                            height: i.height,
                            width: i.width,
                          },
                        ];
                        setImgWidth(i.width);
                        setImgHeight(i.height);
                      };

                      i.src = URL.createObjectURL(e.target.files[0]);
                      setShowImage(true);
                      setShowCanvas(true);
                    }
                  }}
                />
              </Col>
              <Col xs={6}>
                <ModalCompartir
                  downloadCanvas={handleDownloadResp}
                  preRenderImg={preRenderImgResp}
                  getUri={getUriResp}
                  language={language}
                  showCanvas={showCanvas}
                  de={msjDe}
                  para={msjPara}
                  indiceFondo={indexFondo}
                />
              </Col>
              <Col xs={6} style={{ marginTop: "1vh" }}>
                <Button
                  className="christmasCard__botonInputRestart"
                  onClick={() => {
                    startOver();
                  }}
                >
                  <FormattedMessage id="startOver" />
                </Button>
              </Col>
            </Row>
          )}
        </Container>
        <div className="christmasCardResponsive__toolBarResponsive">
          <Row className="christmasCardResponsive__componentDisplay">
            {selectedTab === 1 && (
              <BackGroundManagerResponsive
                setIndex={setindexFondo}
                index={indexFondo}
                setShowCard={setShowCanvas}
                language={language}
              />
            )}
            {selectedTab === 2 && (
              <CustomTextManagerResponsive
                msjNavidad={msjNavidad}
                msjDe={msjDe}
                msjPara={msjPara}
                setMsjNavidad={setMsjNavidad}
                setMsjDe={setMsjDe}
                setMsjPara={setMsjPara}
                showMsjNavidad={showMsjNavidad}
                showMsjDe={showMsjDe}
                showMsjPara={showMsjPara}
                setShowMsjNavidad={setShowMsjNavidad}
                setShowMsjDe={setShowMsjDe}
                setShowMsjPara={setShowMsjPara}
                msjNavidadColor={msjNavidadColor}
                setMsjNavidadColor={setMsjNavidadColor}
                setMsjDeColor={setMsjDeColor}
                setMsjParaColor={setMsjParaColor}
                msjNavidadSize={msjNavidadSize}
                setMsjNavidadSize={setMsjNavidadSize}
                msjDeSize={msjDeSize}
                setMsjDeSize={setMsjDeSize}
                msjParaSize={msjParaSize}
                setMsjParaSize={setMsjParaSize}
                msjNavidadFont={msjNavidadFont}
                setMsjNavidadFont={setMsjNavidadFont}
                msjDeFont={msjDeFont}
                setMsjDeFont={setMsjDeFont}
                msjParaFont={msjParaFont}
                setMsjParaFont={setMsjParaFont}
              />
            )}
            {selectedTab === 3 && (
              <IconManager
                icon1={showIcon1}
                setIcon1={setShowIcon1}
                icon2={showZorro}
                setIcon2={setShowZorro}
                icon3={showIcon3}
                setIcon3={setShowIcon3}
                icon4={showIcon4}
                setIcon4={setShowIcon4}
                icon5={showIcon5}
                setIcon5={setShowIcon5}
                icon6={showIcon6}
                setIcon6={setShowIcon6}
                icon7={showIcon7}
                setIcon7={setShowIcon7}
                icon8={showIcon8}
                setIcon8={setShowIcon8}
              />
            )}
            {selectedTab === 4 && (
              <StickerManager
                bolas={showBolas}
                setBolas={setShowBolas}
                gafas={showGafas}
                setGafas={setshowGafas}
                gorro={showGorro}
                setGorro={setshowGorro}
                regalo={showRegalo}
                setRegalo={setshowRegalo}
              />
            )}
          </Row>
          <Row className="christmasCardResponsive__componentSelector">
            <Col xs={3}>
              <Button
                id={selectedTab === 1 ? "selected" : ""}
                className="christmasCardResponsive__buttonSelector"
                onClick={() => {
                  setSelectedTab(1);
                }}
              >
                {intl.formatMessage({ id: "background" })}
              </Button>
            </Col>
            <Col xs={3}>
              <Button
                id={selectedTab === 2 ? "selected" : ""}
                className="christmasCardResponsive__buttonSelector"
                onClick={() => {
                  setSelectedTab(2);
                }}
              >
                {intl.formatMessage({ id: "text" })}
              </Button>
            </Col>
            <Col xs={3}>
              <Button
                id={selectedTab === 3 ? "selected" : ""}
                className="christmasCardResponsive__buttonSelector"
                onClick={() => {
                  setSelectedTab(3);
                }}
              >
                {intl.formatMessage({ id: "characters" })}
              </Button>
            </Col>
            <Col xs={3}>
              <Button
                id={selectedTab === 4 ? "selected" : ""}
                className="christmasCardResponsive__buttonSelector"
                onClick={() => {
                  setSelectedTab(4);
                }}
              >
                {intl.formatMessage({ id: "stickers" })}
              </Button>
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
};
