import React from "react";
import { Button, Col, Row, Form } from "react-bootstrap";
import balde from "../../../assets/christmasCard/BOTON_COLOR.png";
import { useIntl } from "react-intl";

export const TextCustomButtons = ({
  colorId,
  fontColor,
  setFontColor,
  fontSize,
  setFontSize,
  fontFamily,
  setFontFamily,
  setShowMsj
}) => {
  function setFontFormat(font) {
    switch (font) {
      case "Fat Face":
        setFontFamily("fatFace");
        break;
      case "Courgette":
        setFontFamily("Courgette");
        break;
      case "Fredoka":
        setFontFamily("Fredoka");
        break;
      case "Lobster":
        setFontFamily("Lobster");
        break;
      case "Pacifico":
        setFontFamily("Pacifico");
        break;
      case "Satisfy":
        setFontFamily("Satisfy");
        break;
      case "Shadow Into Light":
        setFontFamily("ShadowIntoLight");
        break;
    }
  }
  const intl = useIntl();
  return (
    <Row className="christmasCard__textManagerButtonRow">
      <Col xs={4} className="christmasCard__textManagerButtonCol">
        <Button className="christmasCard__textManagerButtonColor shadow-none">
          <label htmlFor={colorId}>
            <img
              id="imgPicker"
              alt="icono llenado de fondo"
              src={balde}
              width="60%"
            />
          </label>
          <input
            type="color"
            accept="color"
            value={fontColor}
            className="christmasCard__colorPicker"
            style={{ visibility: " ", width: "20px", height: "20px" }}
            id={colorId}
            onChange={(e) => setFontColor(e.target.value)}
          />
        </Button>
      </Col>
      <Col xs={4} className="christmasCard__textManagerButtonCol">
        <Row
          className="christmasCard__textManagerInnerButtonRow"
          style={{ height: "fit-content", padding: "0 5%" }}
        >
          <Col
            xs={4}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Button
              className="shadow-none christmasCard__fontButton"
              onClick={() => {
                setFontSize(fontSize === 0 ? 0 : fontSize - 1);
              }}
            >
              -
            </Button>
          </Col>
          <Col
            xs={4}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <p style={{ fontWeight: "bold", fontSize: "15px" }}>{fontSize}</p>
          </Col>
          <Col
            xs={4}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Button
              className="shadow-none christmasCard__fontButton"
              onClick={() => {
                setFontSize(fontSize + 1);
              }}
            >
              +
            </Button>
          </Col>
        </Row>
      </Col>
      <Col xs={4} className="christmasCard__textManagerButtonCol">
        <Form.Select
          size="lg"
          style={{ fontFamily: fontFamily }}
          onChange={(e) => {
            setFontFormat(e.target.value);
          }}
        >
          <option> {intl.locale.includes("es") ? "Fuente Letra" : "Letter Font"}</option>
          <option>Fat Face</option>
          <option>Courgette</option>
          <option>Fredoka</option>
          <option>Lobster</option>
          <option>Pacifico</option>
          <option>Satisfy</option>
          <option>Shadow Into Light</option>
        </Form.Select>
      </Col>
    </Row>
  );
};
