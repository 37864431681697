import React, { useState, useRef } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import icon3 from "../../assets/christmasCard/avatares/PINGUINO.png";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import Form from "react-bootstrap/Form";
import moment from "moment";
import { useForm } from "react-hook-form";
import MomentUtils from "@date-io/moment"; // cho
import { KeyboardDatePicker } from "@material-ui/pickers";

import { FormattedMessage } from "react-intl";
import { useIntl } from "react-intl";
import { LoginService, RegisterService } from "../../services/authServices";
import "moment/locale/es";
import { useLocation, useNavigate } from "react-router-dom";
import useAnalyticsEventTracker from "../../services/useAnalyticsEventTracker";

import cabezoteLogin from "../../assets/auth/CABEZOTE.png";
import cabezoteLoginEN from "../../assets/auth/CABEZOTE-EN.png";

import enterAccount from "../../assets/auth/INGRESA-A-TU-CUENTA.png";
import enterAccountEn from "../../assets/auth/INGRESA-A-TU-CUENTA-EN.png";
import axios from "axios";

import cabezoteRegister from "../../assets/auth/CABEZOTE-REGISTER.png";
import crearCuentaRegister from "../../assets/auth/CREAR-CUENTA-REGISTER.png";

import cabezoteRegisterEn from "../../assets/auth/CABEZOTE-REGISTER-EN.png";
import crearCuentaRegisterEn from "../../assets/auth/CREATE-ACCOUNT-REGISTER-EN.png";
export const ModalLoginRegister = ({
  language,
  show,
  setShow,
  redirectUrl,
}) => {
  const gaEventTracker = useAnalyticsEventTracker("Login/Register");
  const lastCountryLocal = localStorage.getItem("lastCountry");
  const path = useLocation().pathname;
  const donde = path.split("/")[1];
  const paises = [
    { BOLIVIA: "BO" },
    { ECUADOR: "EC" },
    { PERU: "PE" },
    { VENEZUELA: "VE" },
    { COLOMBIA: "CO" },
    { COSTA_RICA: "CR" },
    { SALVADOR: "SV" },
    { GUATEMALA: "GT" },
    { HONDURAS: "HN" },
    { PANAMA: "PA" },
    { GUYANAS: "GY" },
    { JAMAICA: "JM" },
    { SURINAM: "SR" },
    { TRINIDAD_TOBAGO: "TT" },
    { REPUBLICA_DOMINICANA: "DO" },
    { PUERTO_RICO: "PR" },
    { USA: "US" },
  ];

  const schema = yup.object().shape({
    documentNumber: yup.string().required("*Este campo es obligatorio"),
    email: yup
      .string()
      .email("*Este campo debe ser un email válido")
      .required("*Este campo es requerido"),
    acceptTerms: yup.bool().oneOf([true], "Accept Ts & Cs is required"),
  });
  const intl = useIntl();
  const navigate = useNavigate();
  const schema2 = yup.object().shape({
    names: yup.string().required("*Este campo es obligatorio"),

    email: yup
      .string()
      .email("*Este campo debe ser un email válido")
      .required("*Este campo es requerido"),

    documentNumber: yup.string().required("*Este campo es obligatorio"),
    gender: yup.string().required("*Este campo es obligatorio"),
    age: yup.string().required("*Este campo es obligatorio"),
    acceptTerms: yup.bool().oneOf([true], "Accept Ts & Cs is required"),
  });

  const [registerForm, setRegisterForm] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => {
    donde === lastCountryLocal ? navigate("christmasCard") : setShow(true);
  };

  const [loginMessage, setLoginMessage] = useState("");
  const [registerMessage, setRegisterMessage] = useState("");

  const enableRegister = () => {
    setRegisterForm(!registerForm);
  };

  const handleLogin = async (data) => {
    try {
      setLoginMessage("");

      const obj = {
        email: data.email,
        cedula: data.documentNumber,
      };

      const service = await LoginService(obj);

      if (service.status === 200) {
        if (service.data.status === 400) {
          setLoginMessage(<FormattedMessage id="wrongPass" />);
        } else {
          localStorage.setItem("isLogged", true);
          navigate(redirectUrl);
        }
      } else if (service.status === 400) {
        setLoginMessage(<FormattedMessage id="wrongPass" />);
      }
    } catch (error) {
      console.log("====================================");
      console.log(error);
      console.log("====================================");
    }
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const {
    register: register2,
    handleSubmit: handleSubmit2,

    formState: { errors: errors2 },
  } = useForm({
    resolver: yupResolver(schema2),
  });

  const handleRegister = async (data) => {
    if (data.gender === "MALE" || data.gender === "HOMBRE") {
      data.gender = "H";
    } else {
      data.gender = "M";
    }
    const codigoPais = paises.filter((pais) => {
      return Object.keys(pais)[0] === donde;
    });
    data.countryCode = Object.values(codigoPais[0])[0];
    const service = await RegisterService(data);
    if (service.status === 200) {
      if (service.data.status === 200) {
        localStorage.setItem("isLogged", true);
        navigate(redirectUrl);
      } else {
        setRegisterMessage(<FormattedMessage id="alreadyRegister" />);
      }
    } else if (service.status === 400) {
      setRegisterMessage(<FormattedMessage id="alreadyRegister" />);
    }
  };

  return (
    <>
      <Modal show={show} onHide={handleClose} centered>
        <Modal.Body className="modalLogin__background">
          <Container>
            {!registerForm ? (
              <>
                <Row style={{ alignItems: "center" }}>
                  <Col xs={5}>
                    <img
                      className="modalLogin__image"
                      src={
                        intl.locale.includes("es")
                          ? cabezoteLogin
                          : cabezoteLoginEN
                      }
                    ></img>
                  </Col>
                  <Col xs={7}>
                    <Row className="text-center responsive__login__padding">
                      <Col xs={12}>
                        <img
                          className="modalLogin__enter"
                          src={
                            intl.locale.includes("es")
                              ? enterAccount
                              : enterAccountEn
                          }
                          style={{ width: "14rem" }}
                        ></img>
                      </Col>

                      <Form onSubmit={handleSubmit(handleLogin)}>
                        <Col className="p-3" xs={12}>
                          <Form.Control
                            className={errors.email ? "input__error" : ""}
                            {...register("email")}
                            type="email"
                            placeholder={intl.formatMessage({ id: "email" })}
                          />
                        </Col>
                        <Col className="p-3" xs={12}>
                          <Form.Control
                            className={
                              errors.documentNumber ? "input__error" : ""
                            }
                            {...register("documentNumber")}
                            placeholder={intl.formatMessage({
                              id: "documentNumber",
                            })}
                          />
                        </Col>

                        <Col xs={12}>
                          <Col xs={12}>
                            <Form.Group controlId="formBasicCheckbox">
                              <Form.Check
                                {...register("acceptTerms")}
                                type="checkbox"
                                label={
                                  <span>
                                    <FormattedMessage id="terms" />
                                    <a
                                      target="_blank"
                                      href="https://colombina.com//uploads/legal/Colombina_aviso-de-privacidad.pdf"
                                    >
                                      <FormattedMessage id="privacy" />
                                    </a>
                                    ,
                                    <a
                                      target="_blank"
                                      href="https://colombina.com//uploads/legal/Colombina_terminos-y-condiciones.pdf"
                                    >
                                      <FormattedMessage id="termsConditions" />
                                    </a>
                                    <a
                                      href="https://colombina.com//uploads/admin/media/upload/tratamiendo-de-datos/politica_proteccion_datos_personales_colombina_2020.pdf"
                                      target="_blank"
                                    >
                                      <FormattedMessage id="termsData" />
                                    </a>
                                    <FormattedMessage id="endTerms" />
                                  </span>
                                }
                              />
                            </Form.Group>
                          </Col>
                        </Col>

                        <p className="modalLogin__title__sm__error">
                          {loginMessage}{" "}
                        </p>
                        <Col className="p-2" xs={12}>
                          {" "}
                          <Button
                            type="submit"
                            className="modalLogin__button"
                            onClick={() => {
                              gaEventTracker("Login");
                            }}
                          >
                            <FormattedMessage id="login" />
                          </Button>
                        </Col>
                        <p className="modalLogin__title__sm">
                          {" "}
                          <FormattedMessage id="dont" />
                        </p>
                        <Col className="p-2" xs={12}>
                          {" "}
                          <Button
                            className="modalLogin__button"
                            onClick={enableRegister}
                          >
                            <FormattedMessage id="create" />
                          </Button>
                        </Col>
                      </Form>
                    </Row>
                  </Col>
                </Row>
              </>
            ) : (
              <>
                <img
                  className="modalRegister__image"
                  src={
                    intl.locale.includes("es")
                      ? cabezoteRegister
                      : cabezoteRegisterEn
                  }
                ></img>
                <div style={{ height: "18rem" }}></div>
                <Row className="text-center register__container">
                  <Col xs={12} className="p-3">
                    <img
                      src={
                        intl.locale.includes("es")
                          ? crearCuentaRegister
                          : crearCuentaRegisterEn
                      }
                    />
                  </Col>
                  <Form onSubmit={handleSubmit2(handleRegister)}>
                    <Col className="p-2" xs={12}>
                      <Form.Control
                        className={errors2.names ? "input__error" : ""}
                        {...register2("names")}
                        placeholder={intl.formatMessage({ id: "names" })}
                      />
                    </Col>

                    <Col className="p-2" xs={12}>
                      <Form.Control
                        className={errors2.email ? "input__error" : ""}
                        {...register2("email")}
                        type="email"
                        placeholder={intl.formatMessage({ id: "email" })}
                      />
                    </Col>

                    <Col className="p-2" xs={12}>
                      <Form.Control
                        className={errors2.documentNumber ? "input__error" : ""}
                        {...register2("documentNumber")}
                        placeholder={intl.formatMessage({
                          id: "documentNumber",
                        })}
                      />
                    </Col>

                    <Col className="p-2" xs={12}>
                      <select
                        {...register2("gender")}
                        className={
                          errors2.gender
                            ? " form-control input__error"
                            : "form-control"
                        }
                      >
                        <option value="">
                          <FormattedMessage id="gender" />
                        </option>
                        <option>
                          <FormattedMessage id="male" />
                        </option>
                        <option>
                          <FormattedMessage id="female" />
                        </option>
                        <option>
                          <FormattedMessage id="other" />
                        </option>
                      </select>
                    </Col>

                    <Col className="p-2" xs={12}>
                      <Form.Control
                        className={errors2.age ? "input__error" : ""}
                        {...register2("age")}
                        placeholder={intl.formatMessage({
                          id: "age",
                        })}
                      />
                    </Col>

                    <Col xs={12}>
                      <Form.Group controlId="formBasicCheckbox">
                        <Form.Check
                          {...register2("acceptTerms")}
                          type="checkbox"
                          label={
                            <span>
                              <FormattedMessage id="terms" />
                              <a
                                target="_blank"
                                href="https://colombina.com//uploads/legal/Colombina_aviso-de-privacidad.pdf"
                              >
                                <FormattedMessage id="privacy" />
                              </a>
                              ,
                              <a
                                target="_blank"
                                href="https://colombina.com//uploads/legal/Colombina_terminos-y-condiciones.pdf"
                              >
                                <FormattedMessage id="termsConditions" />
                              </a>
                              <a
                                href="https://colombina.com//uploads/admin/media/upload/tratamiendo-de-datos/politica_proteccion_datos_personales_colombina_2020.pdf"
                                target="_blank"
                              >
                                <FormattedMessage id="termsData" />
                              </a>
                              <FormattedMessage id="endTerms" />
                            </span>
                          }
                        />
                      </Form.Group>
                    </Col>

                    <p className="modalLogin__title__sm__error">
                      {registerMessage}
                    </p>

                    <Col className="p-2" xs={12}>
                      {" "}
                      <Button
                        type="submit"
                        className="modalLogin__button bg__orange"
                        onClick={() => {
                          gaEventTracker("Register");
                        }}
                      >
                        <FormattedMessage id="send" />
                      </Button>
                    </Col>
                  </Form>
                  <Col className="p-2" xs={12}>
                    <Button
                      className="modalLogin__button bg__orange"
                      onClick={enableRegister}
                    >
                      <FormattedMessage id="back" />
                    </Button>
                  </Col>
                </Row>
              </>
            )}
          </Container>
        </Modal.Body>
      </Modal>
    </>
  );
};
