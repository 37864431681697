import React from "react";

export const ColorSelectorResponsive = ({
  colorId,
  fontColor,
  setFontColor,
}) => {
  return (
    <input
      type="color"
      accept="color"
      value={fontColor}
      className="christmasCard__colorPicker"
      style={{ visibility: " ", width: "20px", height: "20px" }}
      id={colorId}
      onChange={(e) => setFontColor(e.target.value)}
    />
  );
};
