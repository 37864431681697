import React from "react";
import { Outlet, useLocation } from "react-router-dom";
import { Footer } from "./footer";
import { NavBar } from "./navbvar";

export const Layout = ({ setLanguage, lang, playing, setPlaying, audio }) => {
  const path = useLocation().pathname;
  return (
    <div>
      {path !== "/" && (
        <NavBar
          setLanguage={setLanguage}
          lang={lang}
          playingPar={playing}
          setPlayingPar={setPlaying}
          audio={audio}
        />
      )}

      <Outlet />
      {path !== "/" && path != "christmasCard" && path != "christmascard" && (
        <Footer />
      )}
    </div>
  );
};
