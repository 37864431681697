import { useEffect, useState } from "react";

export const useAudio = (audio, playingPar, setPlayingPar) => {
  const toggle = () => {
    if (playingPar) {
      audio.pause();
      setPlayingPar(false);
    } else {
      audio.play();
      setPlayingPar(true);
    }
  };

  useEffect(() => {
    playingPar ? audio.play() : audio.pause();
    playingPar ? (audio.loop = true) : audio.pause();
  }, [playingPar]);

  useEffect(() => {
    audio.addEventListener("ended", () => setPlayingPar(true));
    return () => {
      audio.removeEventListener("ended", () => setPlayingPar(true));
    };
  }, []);

  return [playingPar, toggle];
};
