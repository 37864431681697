import React, { useState } from "react";

import { Stage, Layer, Group, Text, Rect } from "react-konva";
import TransformableImage from "./image";
import { TransformableText } from "./text";

export const CanvasStage = ({
  stageRef,
  width,
  heigth,
  image,
  imageHeight,
  imageWidth,
  showImage,
  imgX,
  imgY,
  personajeX,
  personajeY,
  stickerX,
  stickerY,
  icon2,
  showZorro,
  icon3,
  showIcon3,
  icon1,
  showIcon1,
  icon4,
  showIcon4,
  icon5,
  showIcon5,
  icon6,
  showIcon6,
  icon7,
  showIcon7,
  icon8,
  showIcon8,
  bolasImg,
  showBolas,
  gafasImg,
  showGafas,
  gorroImg,
  showGorro,
  regaloImg,
  showRegalo,
  msjNavidad,
  msjDe,
  msjPara,
  showMsjNavidad,
  showMsjDe,
  showMsjPara,
  fondo,
  msjNavidadFont,
  msjNavidadColor,
  msjNavidadSize,
  msjDeFont,
  msjDeColor,
  msjDeSize,
  msjParaFont,
  msjParaColor,
  msjParaSize,
  msjNavidadX,
  msjNavidadY,
  msjParaX,
  msjParaY,
  msjDeX,
  msjDeY,

  selectedId1,
  selectShape1,
}) => {
  const renderImg = [
    {
      x: width / 2,
      y: heigth / 4,
      id: "renderImg1",
    },
    {
      x: width / 2,
      y: heigth / 2,
      id: "renderPersonaje1",
    },
    {
      x: width / 2,
      y: heigth / 2,
      id: "renderPersonaje2",
    },
    {
      x: width / 2,
      y: heigth / 2,
      id: "renderPersonaje3",
    },
    {
      x: width / 2,
      y: heigth / 2,
      id: "renderPersonaje4",
    },
    {
      x: width / 2,
      y: heigth / 2,
      id: "renderSticker1",
    },
    {
      x: width / 2,
      y: heigth / 2,
      id: "renderSticker2",
    },
    {
      x: width / 2,
      y: heigth / 2,
      id: "renderSticker3",
    },
    {
      x: width / 2,
      y: heigth / 2,
      id: "renderSticker4",
    },
  ];
  const rect = [
    {
      x: msjNavidadX,
      y: msjNavidadY,
      id: "rect1",
    },
    {
      x: msjDeX,
      y: msjDeY,
      id: "rect2",
    },
    {
      x: msjParaX,
      y: msjParaY,
      id: "rect3",
    },
  ];
  const [tranImg, setTranImg] = useState(renderImg);
  const [rectangles, setRectangles] = useState(rect);
  const [selectedId, selectShape] = useState(null);
  const checkDeselect = () => {
    selectShape1(null);
    selectShape(null);
  };
  return (
    <Stage ref={stageRef} width={width} height={heigth}>
      <Layer>
        <TransformableText
          name={"LOAD FAT FACE"}
          shapeProps={rect[2]}
          fontFamily={"fatFace"}
        />
        <TransformableText
          name={"LOAD COURGETTE"}
          shapeProps={rect[2]}
          fontFamily={"courgette"}
        />
        <TransformableText
          name={"LOAD FREDOKA"}
          shapeProps={rect[2]}
          fontFamily={"fredoka"}
        />
        <TransformableText
          name={"LOAD LOBSTER"}
          shapeProps={rect[2]}
          fontFamily={"lobster"}
        />
        <TransformableText
          name={"LOAD PACIFICO"}
          shapeProps={rect[2]}
          fontFamily={"pacifico"}
        />
        <TransformableText
          name={"LOAD SATISFY"}
          shapeProps={rect[2]}
          fontFamily={"satisfy"}
        />
        <TransformableText
          name={"LOAD SHADOW"}
          shapeProps={rect[2]}
          fontFamily={"ShadowIntoLight"}
        />
      </Layer>
      <Layer>
        {fondo === null ? (
          <Rect width={width} height={heigth} fill={"#114a87"} />
        ) : (
          <Rect
            width={width}
            height={heigth}
            fillPatternImage={fondo}
            fillPatternRepeat={"no-repeat"}
            fillPatternScaleX={width / fondo.width}
            fillPatternScaleY={heigth / fondo.height}
          />
        )}
      </Layer>
      <Layer>
        {showImage === true ? (
          <TransformableImage
            image={image}
            imageWidth={imageHeight > imageWidth ? width * 0.4 : width * 0.8}
            imageHeight={imageHeight > imageWidth ? width * 0.8 : width * 0.5}
            onMouseDown={checkDeselect}
            onTouchStart={checkDeselect}
            // xPos={imageHeight>imageWidth? renderImg[0].x - width * 0.2 : renderImg[0].x - width * 0.4}
            // yPos={renderImg[0].y}
            xPos={imgX}
            yPos={imgY}
            isSelected={renderImg[0].id === selectedId1}
            onSelect={() => {
              selectShape1(renderImg[0].id);
            }}
            onChange={(newAttrs) => {
              const imgs = tranImg.slice();
              imgs[0] = newAttrs;
              setTranImg(imgs);
            }}
          />
        ) : (
          <></>
        )}
      </Layer>
      <Layer>
        {showZorro === true ? (
          <TransformableImage
            image={icon2}
            onMouseDown={checkDeselect}
            onTouchStart={checkDeselect}
            imageWidth={width * 0.2}
            imageHeight={heigth * 0.15}
            isSelected={renderImg[1].id === selectedId1}
            xPos={personajeX}
            yPos={personajeY}
            onSelect={() => {
              selectShape1(renderImg[1].id);
            }}
            onChange={(newAttrs) => {
              const imgs = tranImg.slice();
              imgs[1] = newAttrs;
              setTranImg(imgs);
            }}
          />
        ) : (
          <></>
        )}
        {showIcon3 === true ? (
          <TransformableImage
            image={icon3}
            onMouseDown={checkDeselect}
            onTouchStart={checkDeselect}
            imageWidth={width * 0.2}
            imageHeight={heigth * 0.15}
            xPos={personajeX}
            yPos={personajeY}
            // xPos={renderImg[1].x - width * 0.1}
            // yPos={renderImg[1].y}
            isSelected={renderImg[2].id === selectedId1}
            onSelect={() => {
              selectShape1(renderImg[2].id);
            }}
            onChange={(newAttrs) => {
              const imgs = tranImg.slice();
              imgs[2] = newAttrs;
              setTranImg(imgs);
            }}
          />
        ) : (
          <></>
        )}
        {showIcon1 === true ? (
          <TransformableImage
            image={icon1}
            onMouseDown={checkDeselect}
            onTouchStart={checkDeselect}
            imageWidth={width * 0.2}
            imageHeight={heigth * 0.15}
            xPos={personajeX}
            yPos={personajeY}
            isSelected={renderImg[3].id === selectedId1}
            onSelect={() => {
              selectShape1(renderImg[3].id);
            }}
            onChange={(newAttrs) => {
              const imgs = tranImg.slice();
              imgs[3] = newAttrs;
              setTranImg(imgs);
            }}
          />
        ) : (
          <></>
        )}
        {showIcon4 && (
          <TransformableImage
            image={icon4}
            onMouseDown={checkDeselect}
            onTouchStart={checkDeselect}
            imageWidth={width * 0.2}
            imageHeight={heigth * 0.15}
            xPos={personajeX}
            yPos={personajeY}
            // xPos={renderImg[1].x - width * 0.1}
            // yPos={renderImg[1].y}
            isSelected={renderImg[4].id === selectedId1}
            onSelect={() => {
              selectShape1(renderImg[4].id);
            }}
            onChange={(newAttrs) => {
              const imgs = tranImg.slice();
              imgs[4] = newAttrs;
              setTranImg(imgs);
            }}
          />
        )}
        {showIcon5 && (
          <TransformableImage
            image={icon5}
            onMouseDown={checkDeselect}
            onTouchStart={checkDeselect}
            imageWidth={width * 0.2}
            imageHeight={heigth * 0.15}
            xPos={personajeX}
            yPos={personajeY}
            // xPos={renderImg[1].x - width * 0.1}
            // yPos={renderImg[1].y}
            isSelected={renderImg[4].id === selectedId1}
            onSelect={() => {
              selectShape1(renderImg[4].id);
            }}
            onChange={(newAttrs) => {
              const imgs = tranImg.slice();
              imgs[4] = newAttrs;
              setTranImg(imgs);
            }}
          />
        )}
        {showIcon6 && (
          <TransformableImage
            image={icon6}
            onMouseDown={checkDeselect}
            onTouchStart={checkDeselect}
            imageWidth={width * 0.2}
            imageHeight={heigth * 0.15}
            xPos={personajeX}
            yPos={personajeY}
            // xPos={renderImg[1].x - width * 0.1}
            // yPos={renderImg[1].y}
            isSelected={renderImg[4].id === selectedId1}
            onSelect={() => {
              selectShape1(renderImg[4].id);
            }}
            onChange={(newAttrs) => {
              const imgs = tranImg.slice();
              imgs[4] = newAttrs;
              setTranImg(imgs);
            }}
          />
        )}
        {showIcon7 && (
          <TransformableImage
            image={icon7}
            onMouseDown={checkDeselect}
            onTouchStart={checkDeselect}
            imageWidth={width * 0.2}
            imageHeight={heigth * 0.15}
            xPos={personajeX}
            yPos={personajeY}
            // xPos={renderImg[1].x - width * 0.1}
            // yPos={renderImg[1].y}
            isSelected={renderImg[4].id === selectedId1}
            onSelect={() => {
              selectShape1(renderImg[4].id);
            }}
            onChange={(newAttrs) => {
              const imgs = tranImg.slice();
              imgs[4] = newAttrs;
              setTranImg(imgs);
            }}
          />
        )}
        {showIcon8 && (
          <TransformableImage
            image={icon8}
            onMouseDown={checkDeselect}
            onTouchStart={checkDeselect}
            imageWidth={width * 0.2}
            imageHeight={heigth * 0.15}
            xPos={personajeX}
            yPos={personajeY}
            // xPos={renderImg[1].x - width * 0.1}
            // yPos={renderImg[1].y}
            isSelected={renderImg[4].id === selectedId1}
            onSelect={() => {
              selectShape1(renderImg[4].id);
            }}
            onChange={(newAttrs) => {
              const imgs = tranImg.slice();
              imgs[4] = newAttrs;
              setTranImg(imgs);
            }}
          />
        )}
      </Layer>
      <Layer>
        {showBolas === true ? (
          <TransformableImage
            image={bolasImg}
            onMouseDown={checkDeselect}
            onTouchStart={checkDeselect}
            imageWidth={width * 0.15}
            imageHeight={heigth * 0.1}
            xPos={stickerX}
            yPos={stickerY}
            isSelected={renderImg[5].id === selectedId1}
            onSelect={() => {
              selectShape1(renderImg[5].id);
            }}
            onChange={(newAttrs) => {
              const imgs = tranImg.slice();
              imgs[5] = newAttrs;
              setTranImg(imgs);
            }}
          />
        ) : (
          <></>
        )}
        {showGafas === true ? (
          <TransformableImage
            image={gafasImg}
            onMouseDown={checkDeselect}
            onTouchStart={checkDeselect}
            imageWidth={width * 0.15}
            imageHeight={heigth * 0.1}
            xPos={stickerX}
            yPos={stickerY}
            isSelected={renderImg[6].id === selectedId1}
            onSelect={() => {
              selectShape1(renderImg[6].id);
            }}
            onChange={(newAttrs) => {
              const imgs = tranImg.slice();
              imgs[6] = newAttrs;
              setTranImg(imgs);
            }}
          />
        ) : (
          <></>
        )}
        {showGorro === true ? (
          <TransformableImage
            image={gorroImg}
            onMouseDown={checkDeselect}
            onTouchStart={checkDeselect}
            imageWidth={width * 0.15}
            imageHeight={heigth * 0.1}
            xPos={stickerX}
            yPos={stickerY}
            isSelected={renderImg[7].id === selectedId1}
            onSelect={() => {
              selectShape1(renderImg[7].id);
            }}
            onChange={(newAttrs) => {
              const imgs = tranImg.slice();
              imgs[7] = newAttrs;
              setTranImg(imgs);
            }}
          />
        ) : (
          <></>
        )}
        {showRegalo === true ? (
          <TransformableImage
            image={regaloImg}
            onMouseDown={checkDeselect}
            onTouchStart={checkDeselect}
            imageWidth={width * 0.15}
            imageHeight={heigth * 0.1}
            xPos={stickerX}
            yPos={stickerY}
            isSelected={renderImg[8].id === selectedId1}
            onSelect={() => {
              selectShape1(renderImg[8].id);
            }}
            onChange={(newAttrs) => {
              const imgs = tranImg.slice();
              imgs[8] = newAttrs;
              setTranImg(imgs);
            }}
          />
        ) : (
          <></>
        )}
      </Layer>
      <Layer>
        {showMsjNavidad === true ? (
          <TransformableText
            onMouseDown={checkDeselect}
            onTouchStart={checkDeselect}
            name={msjNavidad}
            onSelect={() => {
              selectShape1(rect[0].id);
            }}
            isSelected={rect[0].id === selectedId1}
            onChange={(newAttrs) => {
              const rects = rectangles.slice();
              rects[0] = newAttrs;
              setRectangles(rects);
            }}
            fontFamily={msjNavidadFont}
            fontColor={msjNavidadColor}
            fontSize={msjNavidadSize}
            shapeProps={rect[0]}
            isNavidadMsj={true}
          />
        ) : (
          <></>
        )}
        {showMsjDe === true ? (
          <TransformableText
            onMouseDown={checkDeselect}
            onTouchStart={checkDeselect}
            name={msjDe}
            onSelect={() => {
              selectShape1(rect[1].id);
            }}
            isSelected={rect[1].id === selectedId1}
            onChange={(newAttrs) => {
              const rects = rectangles.slice();
              rects[1] = newAttrs;
              setRectangles(rects);
            }}
            shapeProps={rect[1]}
            fontFamily={msjDeFont}
            fontColor={msjDeColor}
            fontSize={msjDeSize}
          />
        ) : (
          <></>
        )}
        {showMsjPara === true ? (
          <TransformableText
            onMouseDown={checkDeselect}
            onTouchStart={checkDeselect}
            name={msjPara}
            onSelect={() => {
              selectShape1(rect[2].id);
            }}
            isSelected={rect[2].id === selectedId1}
            onChange={(newAttrs) => {
              const rects = rectangles.slice();
              rects[2] = newAttrs;
              setRectangles(rects);
            }}
            shapeProps={rect[2]}
            fontFamily={msjParaFont}
            fontColor={msjParaColor}
            fontSize={msjParaSize}
          />
        ) : (
          <></>
        )}
      </Layer>
    </Stage>
  );
};
