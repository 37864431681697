import React from "react";
import { Button, Container, Row } from "react-bootstrap";
import oso from "../../../assets/2024/christmasCard/NOMO-INICIO-TARJETA.png";
import { BiImageAdd } from "react-icons/bi";
import { FormattedMessage } from "react-intl";
export const Upload = ({ setShowCanvas }) => (
  <Container className="christmasCard__inputContainer">
    <div className="christmasCard__inputDiv">
      <Row
        style={{
          width: "100%",
          height: "20%",
          display: "flex",
          alignItems: "flex-end",
          justifyContent: "Center",
        }}
      >
        <h1 className="christmasCard__inputTitle">
          <FormattedMessage id="letsStart" />
        </h1>
      </Row>
      <Row
        style={{
          width: "100%",
          height: "15%",
          display: "flex",
          alignItems: "start",
          justifyContent: "Center",
          marginTop: "3vh",
        }}
      >
        <Button
          className="christmasCard__botonInput"
          onClick={() => {
            setShowCanvas(true);
          }}
        >
          <label
            htmlFor="contained-button-file"
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <BiImageAdd style={{ marginRight: "8px" }} />
            <FormattedMessage id="createCard" />
          </label>
        </Button>
        {/* <input
          type="file"
          accept="image/*"
          id="contained-button-file"
          style={{ display: "none" }}
          onChange={(e) => {
            if (e.target.files.length > 0) {
              //setUploadedImage(URL.createObjectURL(e.target.files[0]));
              const i = new Image();
              i.onload = function () {
                const imageDimensions = [
                  {
                    height: i.height,
                    width: i.width,
                  },
                ];
                //setImgWidth(i.width);
                //setImgHeight(i.height);
              };

              //i.src = URL.createObjectURL(e.target.files[0]);
              //setShowImage(true);
              setShowCanvas(true);
            }
          }}
        /> */}
      </Row>
      <Row
        style={{
          width: "100%",
          height: "65%",
          display: "flex",
          alignItems: "center",
          justifyContent: "Center",
        }}
      >
        <img className="christmasCard__inputMascota" alt="oso" src={oso} />
      </Row>
    </div>
  </Container>
);
